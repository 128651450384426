import React, { useReducer }  from 'react';
import AddressRepository from './Checkout/AddressRepository';
import AddressTransformer from './Checkout/Transformers/AddressTransformer';

export function StoreReducer(state, action) {
   switch(action.type) {
    case 'setContext': 
        state["context"] = action.value;
        state["checkout"] = createCheckout(state.translations);
        return {...state};
    case 'set':
        state[action.field] = action.value;
        return {...state};
    case 'setWorkflowStatus':
        state.workflowStatus=action.value;
        return {...state};
    case 'setLanguageId':
        state.selectedLanguageId = action.value;
        window.localStorage.setItem('sw_language_id', action.value);
        break;
    case 'setLanguageName':
        state.selectedLanguageName = action.value;
        window.localStorage.setItem('sw_language_name', action.value);
        break;
    case 'setCartExpiration':
        state.selectedLanguageId = action.value;
        window.localStorage.setItem('cartExpiration', action.value);
        break;
    case'setSalutations':
        state.salutations = action.value;
        return {...state};
        break;
    case'setTranslations':

        state.translations = action.value;
        return {...state};
        break;
    case'setCountries':
   
        state.countries = action.value;
        return {...state};
        break;
    case 'setStaticData':
        state.translations = action.value.snippets;
        state.countries = action.value.countries;
        state.languages = action.value.languages;
        state.salutations = action.value.salutations;
        state.navigation = action.value.navigation;
        return {...state};
        break;
    case 'setAddress': 
        state.checkout.address = action.value;
        updateAddress(state.checkout,action.value,state)
        return {...state};
    case 'setShippingAddress': 
        updateAddress(state.checkout,action.value,state)
        state.checkout.shippingAddress = action.value;
        return {...state};
        break;
    case 'setWholeLanguage':
        
        state.selectedLanguageName = action.value[0];
        window.localStorage.setItem('sw_language_name', action.value[0]);
        state.selectedLanguageId = action.value[1];
        window.localStorage.setItem('sw_language_id', action.value[1]);
        return {...state};
        break;
      default:
         return state;
   }
}



function updateAddress(checkout,val,state) {
    if (checkout?.shippingAddress?.countryId !== val.countryId) {
        /*
        CheckoutRepository.updateContext({countryId: val.countryId})
            .then((response) => {
              CartRepository.get().then((cart) => {
                dispatch({type: 'set', field: 'cart', value: cart});
              });
            });
            */
        AddressRepository.updateCountry(val.countryId, checkout)
            .then((cart) => {
                state.cart = cart;
            });
      }
}



function createCheckout(context) {
    let billingAddress = {};
    if (context && context.customer?.defaultBillingAddress) {
      billingAddress = AddressTransformer.appAddress(context.customer.defaultBillingAddress, context.customer);
    }
    let shippingAddress = {};
    if (context && context.customer?.defaultShippingAddress) {
      shippingAddress = AddressTransformer.appAddress(context.customer.defaultShippingAddress, context.customer);
    }
    let shippingMethodId = null;
    let paymentMethodId = null;
    if (context && context.salesChannel) {
      shippingMethodId = context.salesChannel.shippingMethodId;
      paymentMethodId = context.salesChannel.paymentMethodId;
    }


    return {
      address: billingAddress || {},
      shippingAddress: shippingAddress,
      paymentMethodId: paymentMethodId,
      shippingMethodId: shippingMethodId
    };
}