import "./Footer.scss";
import StaticFooterColumn from "chamaeleon-react/components/Footer/StaticFooterColumn";
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import LanguageSwitcherFooter from "../LanguageSwitcher/LanguageSwitcherFooter";
import { StoreContext } from "../Store";
// import LanguageSwitcherFooter from "../LanguageSwitcher/LanguageSwitcherFooter";

import React, { useEffect, useState, useContext } from "react";

function Footer({ languageProp }) {
  const { contextState, setValues } = useContext(StoreContext);

  const translations = contextState.translations;

  const [data, setData] = useState([]);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    let api = new ShopwareAPI();
    api
      .fetch("/navigation/footer-navigation/footer-navigation")
      .then((response) => {
        setData(response);
        setLanguage(languageProp);
      })
      .catch((error) => console.error(`API call error: ${error}`));
  }, []);

  useEffect(() => {
    let api = new ShopwareAPI();
    api
      .fetch("/navigation/footer-navigation/footer-navigation")
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.error(`API call error: ${error}`));
  }, [language]);

  let dynamicColumns: any[] = [];

  data.forEach((col: any) => {
    let childColumns: any[] = [];

    if (col.children && col.children.length) {
      col.children.forEach((child: any) => {
        childColumns.push(
          <li className="unorderedList__item" key={child.id}>
            <a href={child.externalLink} className="footer__link">
              {child.name}
            </a>
          </li>
        );
      });
    }

    dynamicColumns.push(
      <StaticFooterColumn columnClass="col-md-4" key={col.id}>
        <ul className="unorderedList">
          <li className="unorderedList__item" key="headline">
            <span className="footer__headline">
              <a>{col.name}</a>
            </span>
          </li>
          {childColumns}
          {/* <li className="unorderedList__item">
            <a
              href="#"
              id="ot-sdk-btn"
              className="footer__link ot-sdk-show-settings footer__linkbtn"
            >
              {col.name === "Rechtliche Hinweise"
                ? "Cookie-Einstellung bearbeiten"
                : ""}
              {col.name === "Legal Information" ? "Edit cookie settings" : ""}
            </a>
          </li> */}
        </ul>
      </StaticFooterColumn>
    );
  });

  return (
    <div className="container-fluid footer__container">
      {/* <div className="footer__title">Crypto stamp entwickelt für die <a href="https://post.at">Österreichische
            Post AG</a> von <a href="https://capacity.at">Capacity Blockchain Solution GmbH</a></div> */}

      <div className="container">
        <div className="footer_row row">
          <div className="footer__col col">
            <footer className="footer row" role="contentinfo">
              <StaticFooterColumn columnClass="col-md-4">
                <ul className="unorderedList">
                  <li className="unorderedList__item">
                    <span className="footer__hotline">
                      <a className="footer__hotlineLink">
                        {translations["custom.footer-info-header-1"]}
                      </a>
                    </span>
                  </li>
                  <li className="unorderedList__item">
                    {translations["custom.footer-info-mail-label"]}{" "}
                    <a
                      href={"mail:" + translations["custom.footer-info-mail"]}
                      className="footer__link"
                    >
                      {translations["custom.footer-info-mail"]}
                    </a>
                  </li>
                  <li className="unorderedList__item">
                    {translations["custom.footer-info-hotline-label"]}{" "}
                    <a
                      href={"tel:" + translations["custom.footer-info-hotline"]}
                      className="footer__link"
                    >
                      {translations["custom.footer-info-hotline"]}
                    </a>
                  </li>
                  <li className="unorderedList__item">
                    <a
                      href={
                        "https://" + translations["custom.footer-info-link"]
                      }
                      className="footer__link"
                    >
                      {translations["custom.footer-info-link"]}
                    </a>
                  </li>
                  <li className="unorderedList__item">
                    <LanguageSwitcherFooter />
                  </li>
                  <li className="unorderedList__item">
                    <div
                      className="socialIcons"
                      role="region"
                      aria-label="Links zu Social Media Plattformen"
                    >
                      <a
                        href="https://www.facebook.com/unserepost"
                        className="socialIcons__link"
                        target="_blank"
                        title="Post auf Facebook"
                      >
                        <span
                          className="socialIcons__icon posticon_Posticon-SM-Facebook"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Post auf Facebook</span>
                      </a>
                      <a
                        href="https://www.youtube.com/user/unserepost"
                        className="socialIcons__link"
                        target="_blank"
                        title="Post auf YouTube"
                      >
                        <span
                          className="socialIcons__icon posticon_Posticon-SM-Youtube"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Post auf YouTube</span>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/%C3%B6sterreichische-post-ag"
                        className="socialIcons__link"
                        target="_blank"
                        title="Post auf LinkedIn"
                      >
                        <span
                          className="socialIcons__icon posticon_Posticon-SM-Linkedin"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Post auf LinkedIn</span>
                      </a>
                      <a
                        href="https://www.instagram.com/unserepost/"
                        className="socialIcons__link"
                        target="_blank"
                        title="Post auf Instagram"
                      >
                        <span
                          className="socialIcons__icon posticon_Posticon-SM-Instagram"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Post auf Instagram</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </StaticFooterColumn>

              {dynamicColumns}
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
