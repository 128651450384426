import "./HeaderbarLinks.scss";
import { useLocation } from "react-router-dom";
function HeaderbarLinks({device}) {
    let location  = useLocation();
    let selectedLang = 'de';
    const pathname = location.pathname;
    if (pathname.includes('en')) {
        selectedLang = 'en'
    }
    if(device ==="mobile") {
        return (
            <>

                                    <a
                                        className="MobileMenu_navItem"
                                        href="https://cryptostamp.com/international">
                                        International
                                    </a>
                                    <a
                                        className="MobileMenu_navItem"
                                        href="https://cryptostamp.com/how-it-works">
                                        Crypto stamp
                                    </a>
                                    <a
                                        className="MobileMenu_navItem"
                                        href="https://cryptostamp.art/">
                                        Crypto stamp art
                                    </a>
                                    <a
                                        className="MobileMenu_navItem"
                                        href="https://crypto.post.at/wallet">
                                        Shop
                                    </a>
                                    <a href="https://cryptostamp.com/wallet" className="MobileMenu_navItem">
                                        Wallet
                                    </a>
            
            </>  
        );
    }

    return(
        <>
        
        <a
                                    className="Headerbar_link"
                                    href="https://cryptostamp.com/international">
                                    <button type="button">
                                        <span>International</span>
                                    </button>
                                </a>
                                <a
                                    className="Headerbar_link"
                                    href="https://cryptostamp.com/how-it-works">
                                    <button type="button">
                                        <span>Crypto stamp</span>
                                    </button>
                                </a>
                                <a
                                    className="Headerbar_link"
                                    href="https://cryptostamp.art/">
                                    <button type="button">
                                        <span>Crypto stamp art</span>
                                    </button>
                                </a>
                                <a
                                    className="Headerbar_link headerbar_shop"
                                    href={selectedLang === 'de' ? window.location.origin + '/' : window.location.origin +'/en/'}>
                                        <span>Shop</span>

                                </a>
                                <a className="Headerbar_link" href="https://cryptostamp.com/wallet">
                                    <button type="button">
                                        <span>Wallet</span>
                                    </button>
                                </a></>


    )


}

export default HeaderbarLinks;
