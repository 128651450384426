import './Page.scss';
import * as React from 'react';
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import TextBlock from "../CmsBlocks/TextBlock";
import ImageTextBlock from "../CmsBlocks/ImageTextBlock";
import ProductSlider from "../CmsBlocks/ProductSlider";
import FormBlock from "../CmsBlocks/FormBlock";

class Page extends React.Component<any, any> {
    private pageId: any;

    constructor(props: any) {
        super(props);

        this.pageId = this.props.match.params.pageId;

        this.state = {data: []}
    }

    componentDidMount() {
        let vm = this;
        let api = new ShopwareAPI();
        api.fetch('/cms/' + this.pageId)
            .then((response) => {
                vm.setState({data: response});
            })
            .catch(error => console.error(`API call error: ${error}`));
    }

    render() {
        if (!this.state.data) {
            return ('');
        }

        let renderedCmsPage : any[] = [];
        this.state.data.sections.forEach((section : any) => {
            let sectionParts : any[] = [];
            section.blocks.forEach((block : any) => {
                if (block.type === 'text') {
                    sectionParts.push(<TextBlock slots={block.slots} block={block} />);
                } else if (block.type === 'image-text') {
                    sectionParts.push(<ImageTextBlock slots={block.slots} />);
                } else if (block.type === 'product-slider') {
                    sectionParts.push(<ProductSlider slots={block.slots} />);
                } else if (block.type === 'form') {
                    sectionParts.push(<FormBlock slots={block.slots} />);
                }
            });

            renderedCmsPage.push(<div className="section" key={section._uniqueIdentifier}>{sectionParts}</div>);
        });

        return (
            <div>
            {renderedCmsPage}
            </div>
        );
    }
}

export default Page;
