import React from 'react';

import "./HeaderbarLogo.scss";
function HeaderbarLogo() {
  return (
    <>
        <div className="Headerbar_logo">
            <a
                aria-current="page"
                className="text-decoration-none active"
                href="https://crypto.post.at/">
                <img
                    src="/post_logo.svg"
                    alt="Zurück zur Crypto stamp home page"
                />
            </a>
        </div>
    </>  
  );
}

export default HeaderbarLogo;
