

import "./Header.scss";

import React, {useEffect, useState, useContext} from "react";
import {batch, useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import CartIcon from "../Cart/CartIcon";
import CartRepository from "../Cart/CartRepository";
import Notifier from "../Helpers/Notifier";
import HTMLReactParser from 'react-html-parser';
import ShopwareAPI, {BASE_ENDPOINT} from "../ShopwareAPI/ShopwareAPI";
import HeaderbarLogo from "./HeaderbarLogo";
import HeaderbarLinks from "./HeaderbarLinks";
import { StoreContext} from "../Store";


const Header = ({displayHeaderModal}) => {
    
    const { contextState, setValues } = useContext(StoreContext);
    const translations = contextState.translations;
    const context = contextState.context;

  
    const [isActive, setActive] = useState(false);
  
    const handleToggle = () => {
      setActive(!isActive);
    };

    function toggleHumburger() {
        document
            .getElementsByClassName("MobileMenu_humburger")[0]
            .classList.toggle("MobileMenu_humburger_active");
        document
            .getElementsByClassName("MobileMenu_mobileMenuContainer")[0]
            .classList.toggle("MobileMenu_mobileMenuContainer_active");
    }
;
     const cart =  contextState.cart;
     const showAlert =  contextState.showAlert;    


    useEffect(() => {
        if (cart?.extensions?.custom?.expiredAt) {
            setValues({type: "set", field: "workflowStatus", value: CartRepository.inProgress})
        } else {
            setValues({type: "set", field: "workflowStatus", value: CartRepository.failed})
        }
    }, [cart]);

    const location = useLocation();

    const countdownAlert =  contextState.countdownAlert
    const countdownComplete =  contextState.countdownComplete

    function nextDisabled() {
        if (location.pathname !== '/checkout/step4') {
            return "";
        }
        return "disabled";
    }

    function isLoggedIn() {
        if (context?.customer?.customFields?.custom_postcustomer_adb2c?.expires_on) {
            let expiredOn = new Date(context.customer.customFields.custom_postcustomer_adb2c.expires_on * 1000);
            let now = new Date();
            if (expiredOn >= now) {
                return true;
            }
        }

        return false;
    }

    function getUserAcronym() {
        return (context?.customer?.firstName?.toUpperCase()[0] || '') + (context?.customer?.lastName?.toUpperCase()[0] || '');
    }

    function renderLoginLogoutButton() {
        if (isLoggedIn()) {
            return <div className={isActive ? "headerbar__login logged-in active " + translations['custom.kiam'] : "headerbar__login logged-in " + translations['custom.kiam']}>
                <button
                    className="headerbar__loginBtn logged-in"
                    id="contextmenu-lg"
                    onClick={handleToggle} 
                > <span className="headerbar__loginAvatar">{getUserAcronym()}</span>
                </button>
                <nav className="headerbar__login-submenu" id="login-nav" aria-hidden={isActive ? "true" : "false"} aria-labelledby="contextmenu-lg">                                                 
                    <a
                        className="headerbar__login-submenuitem logged-in"
                       data-prevent="true"
                       onClick={() => {
                           let api = new ShopwareAPI();
                           api.fetch('/kiam-logout', 'GET')
                               .then((response: any) => {
                                   window.localStorage.setItem('redirectTo', document.location.pathname);
                                   window.localStorage.setItem('sw_context_token', response.contextToken);
                                   document.location.href = response.link;
                               });
                       }}
                    >Ausloggen</a>
                </nav>
            </div>;

        }

        return( 
            <>
            {/* <div  className={"headerbar__login"}>
                <span className="posticon_Posticon-Avatar-Kreis posticon_Posticon-Avatar-Kreis_mobile"></span>
            </div> */}
            <div className={"headerbar__login " + translations['custom.kiam']} >
            <button className="headerbar__loginBtn logged-out"
                    onClick={() => {
                        let api = new ShopwareAPI();
                        api.fetch('/kiam-login', 'GET')
                            .then((response: any) => {
                                window.localStorage.setItem('redirectTo', document.location.pathname);
                                document.location.href = response.link;
                            });
                    }}
            >
                <span className="posticon_Posticon-Avatar-Kreis"></span>
                <span className="headerbar__btnText">{translations['custom.loginregister']}</span>
                <span className="sr-only">{translations['custom.login']}</span>
            </button>
        </div>
        </>
        )
    }


    /*   useEffect(() => {
      //console.log(cart);

      if (cart && cart.lineItems.length >= 1) {
        dispatch({ type: "set", field: "showAlert", value: true });
        setTimeout(function () {
          dispatch({ type: "set", field: "showAlert", value: false });
          console.log("clear timeout");
        }, 5000);
      }
      //wenn <= 02:00 minuten dann auch anzeigen; console.log(countdown);
    }, cart); */


    if (countdownAlert === true) {
        batch(() => {
            setValues({type: 'set', field: 'countdownAlert', value: false});
        });

        Notifier.success(
            <div className="container">
                <div className="col-12 modal">
                    <div id="up-triangle"></div>
                    <div className="ReactModalConfirmation" id="test">
                        <div className="ReactModalConfirmation__container">
                            <div className="ReactModalConfirmation__header">
              <span
                  aria-hidden="true"
                  className={`ReactModalConfirmation__icon`}></span>
                                <h4 className="ReactModalConfirmation__headline">
                                    {HTMLReactParser(translations['custom.cart.modal.header.warning'])}
                                </h4>
                            </div>
                            <div className="ReactModalConfirmation__body">
                                {HTMLReactParser(translations['custom.cart.modal.text.warning'])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (countdownComplete === true) {
        batch(() => {
            setValues({type: 'set', field: 'countdownComplete', value: false});
        });

        Notifier.success(
            <div className="container">
                <div className="col-12 modal">
                    <div id="up-triangle"></div>
                    <div className="ReactModalConfirmation" id="test">
                        <div className="ReactModalConfirmation__container">
                            <div className="ReactModalConfirmation__header">
              <span
                  aria-hidden="true"
                  className={`ReactModalConfirmation__icon`}></span>
                                <h4 className="ReactModalConfirmation__headline">
                                    {HTMLReactParser(translations['custom.cart.modal.header.end'])}
                                </h4>
                            </div>
                            <div className="ReactModalConfirmation__body">
                                {HTMLReactParser(translations['custom.cart.modal.text.end'])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    function showModal() {
        if (showAlert === true) {
          
            return (
                Notifier.success(
                    <div className="container">
                        <div className="col-12 modal">
                            <div id="up-triangle"></div>
                            <div className="ReactModalConfirmation" id="test">
                                <div className="ReactModalConfirmation__container">
                                    <div className="ReactModalConfirmation__header">
                            <span
                                aria-hidden="true"
                                className={`ReactModalConfirmation__icon`}>
                            </span>
                                        <h4 className="ReactModalConfirmation__headline">
                                            {HTMLReactParser(translations['custom.cart.modal.header'])}
                                        </h4>
                                    </div>
                                    <div className="ReactModalConfirmation__body">
                                        {HTMLReactParser(translations['custom.cart.modal.text'])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )
        }
    }

    let cartIcon = (<CartIcon/>);

    
    if(contextState.showAlert === true) {
        setValues({type: "set", field: "showAlert", value: false});
    }

    return (
        <div>
            <div className="Headerbar_headerbar__container">


                {/* HEADERBAR MOBILE */}
                <div className="Headerbar_mobile">
                    <HeaderbarLogo/>
                    <div className="Headerbar_searchbar headerbar">
                        <div className="headerbar__links">
                            <ul className="headerbar__linklist">
                                {/* Hier das Posticon einfügen */}
                                <li>
                                    <span className="posticon_Posticon-Avatar-Kreis"></span>
                                </li>
                                {renderLoginLogoutButton()}
                                <li className={"react-carticon headerbar__icon--cart headerbar__icon--cart-lg carticon " + nextDisabled()}>
                                    <CartIcon></CartIcon>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="Headerbar_humburger">
                        <div className="MobileMenu_humburger" onClick={toggleHumburger}>
                            <div>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="MobileMenu_mobileMenuContainer">
                            <div className="MobileMenu_mobileMenuOverlay"></div>
                            <div className="MobileMenu_humburger"></div>
                            <div className="MobileMenu_mobileMenuContent">
                                <div className="MobileMenu_mobileMenuLogo">
                                    <HeaderbarLogo/>
                                </div>
                                <div className="MobileMenu_mobileMenuBody">
                                   <HeaderbarLinks device={"mobile"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Headerbar Desktop */}

                <div className="Headerbar_desktop">
                    <div className="container">
                        <div className="Headerbar_headerbar headerbar searchbar--initial">
                            
                            <div className="Headerbar_headerbar__left">
                                <HeaderbarLogo/>
                                <HeaderbarLinks device={"desktop"}/>
                            </div>

                            <div className="Headerbar_headerbar__right">
                                {renderLoginLogoutButton()}
                                <div className="headerbar__links">
                                    <ul className="headerbar__linklist">
                                        <li className={"react-carticon headerbar__icon--cart headerbar__icon--cart-lg carticon " + nextDisabled()}>
                                            <>
                                            {cartIcon}
                                            {showModal()}
                                            </>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
