import { AppProps } from './SalutationField';
import {ErrorMessage, Field} from 'formik'

interface CountriesProps extends AppProps {
    countries:any[]
}

const CountriesField = ({translations,countries,state,changeHandler}:CountriesProps) => {

    const renderCountryOptions = (translations,countries,state) => {
        let renderedCountries = []
        renderedCountries.push(<option value="" key="">{translations['custom.select.text']}</option>)

        countries.sort((a, b) => (a.name > b.name) ? 1 : -1).forEach(country => {
            renderedCountries.push(
                <option id={country.id} value={country.id} defaultValue={state?.country?.id} key={country.id}>
                    {country.name}
                </option>
            )
        })
        return renderedCountries
    }




  return (
      <>
      <label
        htmlFor="countryId">{translations['address.countryLabel']}
        </label>
    <Field
    as="select"
    name="countryId"
    className="cham-form"
    placeholder="Country"
    onChange={e => {changeHandler(e)}}
        >
    {renderCountryOptions(translations,countries,state)}
        </Field>
        <ErrorMessage
            name="countryId"
            component="div"
            className="error"
            />
            </>
  );
}

export default CountriesField;
