import "./Category.scss";
import React, {useEffect, useState, useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import CategoryRepository from "./CategoryRepository";
import CartRepository from "../Cart/CartRepository";
import MiniCart from '../Cart/MiniCart';
import ProductListItem from "./ProductListItem";
import {getGAHook} from "../GoogleAnalytics/GAServiceHook";
import { StoreContext } from "../Store";
import { v4 as uuidv4 } from 'uuid';
import {Button} from "@acl/design-base";
import categoryRepository from "./CategoryRepository";


function Category(props) {
    const { contextState, setValues} = useContext(StoreContext)

    const navigation =  contextState.navigation;
    const translations =  contextState.translations;
    const [products, setProducts] = useState(null);
    const [media, setMedia] = useState(null);
    const [category, setCategory] = useState(null);
    const selectedLanguageName =  contextState.selectedLanguageName;
    const [counter, setCount] = useState(0);
    const cart =  contextState.cart;
    const {fireProductImpression} = getGAHook();
    const history = useHistory();

    useEffect(() => {
        if(products) {
            setTimeout(() => {
                fireProductImpression(products)
            }, 3000);
        }
    }, [products]);

    useEffect(() => {  
        const categoryId = CategoryRepository.getCurrentCategoryId(props.match.params.category, navigation);

        CategoryRepository.categoryDetails(categoryId).then(category => {
            let mediaId = category?.customFields?.custom_category_header_image_data;
            CategoryRepository.getCategoryMediaById(mediaId).then(media =>  {
                setMedia(media.url)
            });
        });
        CategoryRepository.products(categoryId).then(products => {
            setProducts(products);
        });

    }, []);

    // useEffect(() => {  
    //     const categoryId = CategoryRepository.getCurrentCategoryId(props.match.params.category, navigation);
    //     CategoryRepository.categoryDetails(categoryId).then(category => {
    //         setCategory(category);
    //     });
    // }, [category]);

    function addToCart(productId: string, quantity: number = 1) {

        setValues({type: "set", field: "workflowStatus", value: null})
        
        if (!cart || counter < 1) {
            setValues({ type: "set", field: "showAlert", value: true });
        }else if (cart["lineItems"].length === 0){
            setValues({ type: "set", field: "showAlert", value: false });
        }

        CartRepository.add(productId, quantity).then((cart: any) => {
            setValues({type: "set", field: "cart", value: cart});
        });
        

        setCount(counter + 1);
    }

  
  
    
    if (products === null) {
        return <React.Fragment></React.Fragment>;
    }

    let renderedProducts = [];
    products.forEach((product,i) => {
        renderedProducts.push(
            <ProductListItem 
                product={product} 
                position={i} 
                translations={translations} 
                addToCart={addToCart}
                key={product.id}
                />
        );
    });

    function notificationText() {
        // Anzahl der ausverkauften Produkte
        let soldOutProducts = products.filter(e => e.availableStock <= 0).length;
        // Anzahl der ausverkauften Produkte
        let soldOutProductsData = products.filter(e => e.availableStock <= 0);
        // Anzahl der ausreservierten oder ausverkauften Produkte
        let soldOutOrReservedProducts = products.filter(e => e.customFields?.customAvailableStock <= 0).length;
        // Anzahl der ausreservierten oder ausverkauften Produkte
        let soldOutOrReservedProductsData = products.filter(e => e.customFields?.customAvailableStock <= 0);
        // Mind. 1 Produkt ausverkauft
        let isOneProductSoldOut = soldOutProducts > 0;
        // Mind. 1 Produkt ausreserviert oder ausverkauft
        let isOneProductSoldOutOrReserved = soldOutOrReservedProducts > 0;
        // Alle Produkte ausverkauft
        let isAllProductSoldOut = soldOutOrReservedProducts === products.length;
        // Alle Produkte ausreserviert oder ausverkauft
        let isAllProductSoldOutOrReserved = soldOutOrReservedProducts === products.length;

        /*
        notification texte sind custom felder AM PRODUKT => das ist nicht gut im Kontext einer Kategorie. Wäre es nicht besser,
        diese Texte als Snippet zu definieren bzw. wenigstens zusätzlich dazu auf der Kategorie entsprechende Felder anzulegen?
        Bis dahin die Texte des 1. Produkts nehmen.
        */

        // Die Messages hier passen ja noch nicht ganz, da wäre es doch gut auf Kategorie abgestimmte Messages zu haben?
        // zB "Das Kontingent dieser Motive ..." anstatt "dieses Motivs"?
        if (isOneProductSoldOut) {
            return translations['custom.product.partialoutofstock'];
        } else if (isOneProductSoldOutOrReserved) {
            return translations['custom.product.partialreserved'];
        } else if (isAllProductSoldOut) {
            return translations['custom.product.outofstock'];
        } else if (isAllProductSoldOutOrReserved) {
            return translations['custom.product.reserved'];
        } else {
            return "";
        }
    }

    return (
        <div className="headerspace">
            <div className="container content__container">
                <div className="row content__row">
                    <div className="col-md-12 col-lg-8">
                        <div className="centerstage">
                            <main role="main" className="main">
                                <div className="container content__container">
                                    <img className="container" src={media}></img>
                                    {/* <h1 className="heading heading--fineline-inline">
                                        <span>{HTMLReactParser(navigation[1].children[0].name)}</span>
                                    </h1>
                                    <span>{HTMLReactParser(navigation[1].children[0].description)}</span> */}
                                    <br/><br/>
                                    <div className={"notification " + (notificationText() !== '' ? '' : 'disabled')}>
                                        <div className="notification__icon__box">
                                            <span
                                            className={`notification__icon posticon_Posticon-Information`}
                                            aria-hidden="true"></span>
                                        </div>
                                        <div className="notifications__container">
                                            <div className="notification__text">
                                            { notificationText() }
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <div className="row">
                                            {renderedProducts}
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    <div className="sidebar col-md-12 col-lg-4">
                        <div className="sidebar__container">
                            <MiniCart />
                            <aside className="sidebar__main">
                                <div className="container">

                                <Link
                                    to={(selectedLanguageName === "English" ? "/en/cart" : "/cart")}
                                    className="productdetailsbutton linkbtn linkbtn__primary">
                                    <span className="linkbtn__text">{translations['custom.addtobasketbutton']}</span>
                                    <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                                </Link>
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                        {translations['custom.order-info-header']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                        {translations['custom.order-info-text']}
                                        {" "}
                                    </p><br />
                                    <p className="zahlung-und-versand-info__content">
                                        {translations['custom.order-info-text-tax']}
                                    </p>
                                    </div>
                                </div>
                                </div>

                                <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                        {translations['custom.order-info-header-delivery']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                        {translations['custom.order-info-text-delivery']}
                                    </p>
                                    </div>
                                </div>

                                <div className="container"></div>
                                <div className="row" style={translations['custom.onchainblockdisabled'] == 'true' ? { display:'none' } : { display:'block' }}>
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                        {translations['custom.order-info-header-onchain']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                        {translations['custom.order-info-text-onchain']}
                                    </p>
                                    <Button
                                        text={translations['custom.onchainlinkbutton']}
                                        href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                        ? "#"
                                        : translations['custom.onchainlink']}
                                        classes={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}
                                    />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                    {translations['custom.order-info-header-payment'] } 
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                    {translations['custom.order-info-text-payment'] } 
                                    </p>
                                    <div className="zahlung-und-versand-info__images">
                                        <div className="zahlung-und-versand-info__image">
                                        <img src="/payment-logos/Klarna.svg" alt="klarna"></img>
                                        </div>
                                        <br />
                                        <div className="zahlung-und-versand-info__image">
                                        <img src="/payment-logos/mc.svg" alt="mc"></img>
                                        </div>
                                        <div className="zahlung-und-versand-info__image">
                                        <img src="/payment-logos/visa.svg" alt="visa"></img>
                                        </div>
                                        <br />
                                        {/* <div className="zahlung-und-versand-info__image">
                                        <img src="/payment-logos/DCI.svg" alt="dci"></img>
                                        </div> */}
                                    </div>
                                    </div>
                                    <div className="col-12 offset-md-1 col-md-5 col-lg-12 offset-lg-0 panel-right"></div>
                                </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default Category;