import CartRepository from "../Cart/CartRepository";
import CheckoutRepository from "./CheckoutRepository";

class AddressRepository {
    private static isLoading: boolean = false;

    public static updateCountry(countryId, customer)
    {
        return CheckoutRepository.updateContext({countryId: countryId})
            .then((response) => {
                if (!customer?.id) {
                    return null;
                }
                customer.defaultBillingAddress.countryId = countryId;
                customer.defaultBillingAddress.country.id = countryId;
                customer.defaultShippingAddress.countryId = countryId;
                customer.defaultShippingAddress.country.id = countryId;

                if (customer.customFields?.custom_postcustomer_ssoid) {
                    delete (customer.defaultShippingAddress.id);
                    return CheckoutRepository.updateAddress(customer.defaultShippingAddress, 'shipping');
                }

                return CheckoutRepository.updateAddress(customer.defaultShippingAddress, 'shipping');
            })
            .then((response) => {
                if (response && customer?.id && !customer?.defaultShippingAddress?.id) {
                    return CheckoutRepository.updateContext({shippingAddressId: response.id});
                }
            }).then((response) => {
                return CartRepository.get();
            });
    }
}

export default AddressRepository;