import "./LinkButtonGroup.scss";
import HTMLReactParser from "react-html-parser";
import {Link} from "react-router-dom";
import { Button } from "@acl/design-base";


interface LinkButtonGroupProps {
    translations: Object,
    buttonLink: string,
    buttonTitle: string,
    buttonDisabledClass: string,
    soldOutText:string
}

const LinkButtonGroup = ({translations,buttonLink,buttonTitle,buttonDisabledClass,soldOutText}:LinkButtonGroupProps) => {
  return (<>
  
            
                <div className="header_btn" id="header_marginbtn">
                    <div className="header_linkbtn">
                        <Button
                            text={buttonTitle}
                            href={buttonLink}
                            classes={"linkbtn linkbtn__primary " + buttonDisabledClass}
                        />                 
                    </div>
                    <div className="header_linkbtn_secondary">
                        <Button
                            href={translations['custom.secondaryhomebuttonteaserdisabled'] == 'disabled' ? "#" : translations['custom.secondaryhomebuttonteaserlink']}
                            classes={"linkbtn linkbtn__secondary " + translations['custom.secondaryhomebuttonteaserdisabled']} 
                            text={translations['custom.secondaryhomebutton']}
                            // text={translations['custom.onchainbutton']}
                            // href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                            // ? "#"
                            // : translations['custom.onchainbuttonteaserlink']}
                            // classes={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}
                        />    
                    </div>
                    <br/>
                </div>
                <a className="homelink" href={translations['custom.homelinkdisabled'] == 'disabled' ? "#" : translations['custom.homelink']}> 
                        {translations['custom.homelinktext']}  
                </a>
            <div className={"header_sold " + (buttonDisabledClass !== '' ? '' : 'disabled')}>
                {HTMLReactParser(soldOutText)}
            </div>
  </>)
  ;
}



export default LinkButtonGroup;
