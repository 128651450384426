import axios from 'axios';
// import Notifier from "../Helpers/Notifier";

export const BASE_ENDPOINT = '/store-api';

class ShopwareAPI {
    public static instance: any;
    public static setup: boolean = false;

    async preflight() {
        if (!ShopwareAPI.setup) {
            ShopwareAPI.instance = axios.create({
                baseURL: process.env.REACT_APP_SW_API_BASE_URL
            });
            ShopwareAPI.instance.defaults.headers.common['sw-access-key'] = process.env.REACT_APP_SW_API_ACCESS_KEY;

            ShopwareAPI.setup = true;
        }

        if (window.localStorage.getItem('sw_language_id') !== null) {
            ShopwareAPI.instance.defaults.headers.common['sw-language-id'] = window.localStorage.getItem('sw_language_id');
        }

        if (window.localStorage.getItem('sw_context_token') !== null) {
            ShopwareAPI.instance.defaults.headers.common['sw-context-token'] = window.localStorage.getItem('sw_context_token');
        }

        return true;
    }

    async fetch(endpoint: string, method: string = 'POST') {
        await this.preflight();

        let callMethod = (method === 'GET' ? ShopwareAPI.instance.get : ShopwareAPI.instance.post);

        return callMethod(BASE_ENDPOINT + endpoint)
            .then((response: any) => {
                if (response && response.data) {
                    return response.data;
                }
            })
            //.catch((error: any) => console.error(`API call error: ${error}`));
            .catch((error: any) => {
                if (error.response.status == 500 || (error.response.status >= 403 && error.response.status <=420)) {
                    // Notifier.error("Something went wrong. Please try again.");
                    localStorage.clear();
                    setTimeout(() => {window.location.href = "/"}, 5000);
                }
            });
    }

    async write(endpoint: string, data: object, method: string = 'POST') {
        await this.preflight();

        let callMethod = ShopwareAPI.instance.post;

        if (method === 'DELETE') {
            callMethod = ShopwareAPI.instance.delete;
        } else if (method === 'PATCH') {
            callMethod = ShopwareAPI.instance.patch;
        }

        return callMethod(BASE_ENDPOINT + endpoint, data)
            .then((response: any) => {
                if (response && response.data) {
                    if (response.headers['sw-context-token']) {
                        if (response.headers['sw-context-token'] !== window.localStorage.getItem('sw_context_token')) {
                            window.localStorage.setItem('old_context_token', window.localStorage.getItem('sw_context_token'));
                        }
                        window.localStorage.setItem('sw_context_token', response.headers['sw-context-token']);
                        ShopwareAPI.instance.defaults.headers.common['sw-context-token'] = response.headers['sw-context-token'];
                    }

                    return response.data;
                }
            })
            .catch((error: any) => {
                if (error.response.status == 500 || (error.response.status >= 403 && error.response.status <=420)) {
                    //Notifier.error("Something went wrong. Please try again.");
                    localStorage.clear();
                    setTimeout(() => {window.location.href = "/"}, 5000);
                }
            });
    }
}

export default ShopwareAPI;