import "./Cart.scss";

import React, {useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import CartLifeTime from "./CartLifeTime";
import { StoreContext } from "../Store";

function CartIcon() {
    const { contextState, setValues } = useContext(StoreContext);
    const cart =  contextState.cart;
    const [itemCount, setItemCount] = useState(0);

    useEffect(() => {
        if (cart && cart.lineItems && cart.lineItems.length) {
            //if (cart.lineItems.length === 1) {
                let quantity = 0;

                cart.lineItems.forEach((lineItems: any) => {
                    quantity += lineItems.quantity;
                });
                setItemCount(quantity);
            /* } else {
                setItemCount(cart.lineItems.length);
            } */
        } else {
            setItemCount(0);
        }
    }, [cart]);


    const linkText = (contextState.selectedLanguageName === "English" ? "/en/cart" : "/cart")

    if (itemCount !== 0) {
        return (
            <Link
                to = {linkText}
                title="Warenkorb"
                className="link__cart posticon_Posticon-Post-Onlineshop">
                    <span className="link__cart--number">
                        <span className="sr-only">{itemCount} Warenkorb</span>{itemCount}
                    </span>
                <div className="countdown"><CartLifeTime /></div>
            </Link>
        );
    }
    return (
        <Link
            to = {linkText}
            title="Warenkorb"
            className="link__cart posticon_Posticon-Post-Onlineshop">
                    <span className="link__cart--number">
                        <span className="sr-only">0 Warenkorb</span> 0
                    </span>
            <div className="countdown"></div>
        </Link>
    );
}

export default CartIcon;