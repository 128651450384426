import { useContext, useEffect, useState } from "react";
import { StoreContext } from "./Store";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Cart from "./Cart/Cart";
import CheckoutSuccessPage from "./Checkout/CheckoutSuccessPage";
import CheckoutCancelPage from "./Checkout/CheckoutCancelPage";
import Page from "./Page/Page";
import ProductDetail from "./ProductDetail/ProductDetail";
import CheckoutAddresses from "./Checkout/Steps/CheckoutAddresses";
import CheckoutSummary from "./Checkout/Steps/CheckoutSummary";
import CheckoutCommit from "./Checkout/Steps/CheckoutCommit";
import {ToastContainer} from 'react-toastify';
import Category from "./Category/Category";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ShopwareAPI from "./ShopwareAPI/ShopwareAPI";
import React from "react";

function AppRouter() {
    const { setValues,contextState } = useContext(StoreContext);
    const [displayHeaderModal, setDisplayHeaderModal] = useState(false);

  
    
   


    useEffect(()=>{
        const en = ["English","2fbb5fe2e29a4d70aa5854ce7ce3e20b"];
        const de = ["Deutsch","ea1f664a122146c1adb14ac208848743"];
        const pathnameWindow = window.location.pathname;
        const language = (pathnameWindow.includes("/en") || pathnameWindow.includes("/en/")) ? en : de;
        //avoid infinite loop on updating language
        if(contextState.selectedLanguageName === language[0]) return;
        setValues({type: "setWholeLanguage", value: language})
    }, []);

    useEffect(()=>{
        let api = new ShopwareAPI();
            Promise.all([
            api.fetch('/static-data', 'GET'),
        ]).then((data) => {
            setValues({type: 'setTranslations', value: data[0].snippets});
            setValues({type: 'setSalutations', value: data[0].salutations});
            setValues({type: 'setCountries', value: data[0].countries});
        });
    },[contextState.selectedLanguageName])

    return             <Router >
    <ToastContainer/>
    <div className="App">
        <Header displayHeaderModal={displayHeaderModal}/>
        <div id="content" className="contentContainer">
            <ScrollToTop/>
            <Switch>
                <Route path="/cms/:pageId" component={Page}/>
                <Route path={["/product/:productId","/en/product/:productId"]} component={ProductDetail}/>
                <Route
                    path={["/cart","/en/cart"]}
                    component={() => (
                        <Cart setDisplayHeaderModal={setDisplayHeaderModal}/>
                    )}
                />
                <Route path={["/en/checkout/shipment-infos","/checkout/shipment-infos"]} component={CheckoutAddresses}/>
                <Route path={["/checkout/summary","/en/checkout/summary"]} component={CheckoutSummary}/>
                <Route path={["/checkout/payment","/en/checkout/payment"]}  component={CheckoutCommit}/>
                <Route path={["/checked-out/success","/en/checked-out/success"]} component={CheckoutSuccessPage}/> 
                <Route path={["/checked-out/cancel","/en/checked-out/cancel"]} component={CheckoutCancelPage}/>
                <Route path={["/home/:category","/en/home/:category"]} component={Category}/>
                <Route
                    path={["/","/en"]}
                    component={() => (
                        <Home setDisplayHeaderModal={setDisplayHeaderModal}/>
                    )}
                />
                <Redirect to="/"/>
            </Switch>
        </div>
        <div className="spinner">
            <div></div>
        </div>
         <Footer
         languageProp={contextState.selectedLanguageName}
         /> 
    </div>
</Router>
}

export default AppRouter