import "./Checkout.scss";


import { StoreContext } from "./../Store";
import {useEffect, useContext} from "react";
import {useHistory} from "react-router-dom";
//import {useDispatch} from 'react-redux';
import CheckoutRepository from "./CheckoutRepository";
import CartRepository from "../Cart/CartRepository";

function CheckoutCancelPage(props) {
    const history = useHistory();
    //const dispatch = useDispatch();

    const { setValues,contextState } = useContext(StoreContext);

    useEffect(() => {
        //history.push('/checkout/shipment-infos', { from: 'cancel' });
        CheckoutRepository.recreateCart().then((resp) => {
            window.localStorage.setItem('sw_context_token', resp.context.token);

            //let promises = [];

            if (resp?.cart?.extensions?.custom?.expiredAt) {
                setValues({type: "set", field: "setWorkflowStatus", value: CartRepository.inProgress});
            }

            setValues({type: "set", field: "cart", value: resp.cart});
            setValues({type: "setContext", field: "context", value: resp.context});

            // Promise.all(promises).then((res) => {
            history.push('/checkout/shipment-infos', { from: 'cancel' });
            // });
        });
    }, []);

    return (
        <div>
            <div className="spinner">
                <div></div>
            </div>
        </div>
    );
}

export default CheckoutCancelPage;
