import ShopwareAPI from '../ShopwareAPI/ShopwareAPI';
import updateContextStruct from "./Structs/updateContextStruct";
import CartRepository from "../Cart/CartRepository";
import { useContext } from 'react';
import { StoreContext } from '../Store';

class CheckoutRepository {
    private static isLoading: boolean = false;

    public static async countries() {
        let api = new ShopwareAPI();
        return api.fetch('/country')
            .then((response: any) => {
                if (response && response.elements) {
                    return response.elements;
                }
            });
    }

    public static async salutations() {
        let api = new ShopwareAPI();
        return api.fetch('/salutation')
            .then((response: any) => {
                return response;
            });
    }

    public static async paymentMethods() {
        let api = new ShopwareAPI();
        return api.fetch('/payment-method?onlyAvailable=1')
            .then((response: any) => {
                return response;
            });
    }

    public static async shippingMethods() {
        let api = new ShopwareAPI();
        return api.fetch('/shipping-method?onlyAvailable=1')
            .then((response: any) => {
                return response;
            });
    }

    public static async createCustomer(customer) {
        customer.guest = true;
        let api = new ShopwareAPI();
        return api.write('/account/register', customer)
            .then((response: any) => {
                return response;
            });
    }

    public static async updateAddress(address, addressType = 'billing') {
        let api = new ShopwareAPI();

        let url = '/account/address';
        let method = 'POST';

        if (address.id) {
            url += '/' + address.id;
            method = 'PATCH';
        }

        let newAddress = null;
        return api.write(url, address, method)
            .then((response: any) => {
                newAddress = response;
                let ctxKey = addressType + 'AddressId';
                let ctxStruct = {};
                ctxStruct[ctxKey] = newAddress.id;
                return Promise.all([
                    api.write('/context', ctxStruct, 'PATCH')
                ]).then((res) => {
                    return newAddress;
                });
            });
    }

    public static async updateContext(data: updateContextStruct) {
        let api = new ShopwareAPI();

        return api.write('/context', data, 'PATCH')
            .then((response: any) => {
                return response;
            });
    }

    public static async order(customer, paymentMethodId: string = null, shippingMethodId: string = null, selectedLanguageName = "Deutsch") {
        let api = new ShopwareAPI();
        let currentDynamicData;
        return api.write('/context', {
            paymentMethodId: paymentMethodId,
            shippingMethodId: shippingMethodId
        }, 'PATCH')
            .then((response: any) => {
                return api.fetch('/dynamic-data', 'GET')
                    .then((response) => {
                        currentDynamicData = response;
                        return response;
                    });
            }).then((response) => {
                if (!currentDynamicData.newsletter && customer.newsletter) {
                    api.write('/newsletter/subscribe', {
                        email: customer.email,
                        option: 'direct',
                        storefrontUrl: customer.storefrontUrl
                    }, 'POST');
                } else if (currentDynamicData.newsletter && !customer.newsletter) {
                    api.write('/newsletter/unsubscribe', {email: customer.email}, 'POST');
                }
                return CheckoutRepository.createCustomer(customer);
            })
            .then((customer) => {
                let api = new ShopwareAPI();
                return api.write('/checkout/order', {})
                    .then((response: any) => {
                        return response;
                    });
            })
            .then((order) => {
                sessionStorage.setItem('ga-data', JSON.stringify(order));
                let api = new ShopwareAPI();
                let successPath = "/checked-out/success"
                if(selectedLanguageName === "English") {
                    successPath = "/en" + successPath 
                }
                let cancelPath = "/checked-out/cancel"
                //let cancelPath = "/"
                if(selectedLanguageName === "English") {
                    cancelPath = "/en" + cancelPath 
                }
                return api.write('/handle-payment', {
                    /*hier den Context abfragen ob Englisch oder Deutsch unb dann auf richtige Dankesseite finish url*/
                    "orderId": order.id,
                    "finishUrl": process.env.REACT_APP_BASE_URL + "/breakout.html?to=" + successPath,
                    "errorUrl": process.env.REACT_APP_BASE_URL + "/breakout.html?to="+ cancelPath
                })
                    .then((response: any) => {
                        if (window.localStorage.getItem('expire_at') != null) {
                            window.localStorage.removeItem('expire_at');
                        }
                        return response;
                    });
            });
    }

    public static async recreateCart() {
        let api = new ShopwareAPI();
        let latestOrder;

        return api.write('/order', {
            "limit": 1,
            "sort": [
                {
                    "field": "orderDateTime",
                    "order": "desc",
                    "naturalSorting": true
                }
            ],
            "associations": {
                "lineItems": {}
            }
        }).then((orderResult) => {
            latestOrder = orderResult.orders.elements[0];

            let payload = latestOrder.lineItems.map((lineItem) => {
                return {
                    type: 'product',
                    referencedId: lineItem.productId,
                    quantity: lineItem.quantity
                };
            });

            delete (ShopwareAPI.instance.defaults.headers.common['sw-context-token']);
            window.localStorage.removeItem('sw_context_token');
            return api.write('/checkout/cart/custom-line-item', {items: payload});
        }).then((cart) => {
            window.localStorage.setItem('sw_context_token', cart.token);

            let shippingAddress = window.localStorage.getItem('VersandInformationen');
            if (shippingAddress) {
                let shippingAddressParsed = JSON.parse(shippingAddress);
                if (shippingAddressParsed.countryId) {
                    CheckoutRepository.updateContext({countryId: shippingAddressParsed.countryId});
                }
            }

            return api.fetch('/dynamic-data', 'GET');
        });
    }
}

export default CheckoutRepository;