import "./ImageBlock.scss";
import Hero_Main from "chamaeleon-react/components/Hero/Hero_Main.tsx";
import Hero_Main_Right from "chamaeleon-react/components/Hero/Hero_Main_Right.tsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import HTMLReactParser from "react-html-parser";
import React from 'react';
import {Link} from "react-router-dom";
import Headline from "chamaeleon-react/components/Headline/Headline";
import {useSelector} from "react-redux";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Button } from "@acl/design-base";

const ImageBlock: any = (props: any) => {
    const translations = useSelector((state: any) => state.translations);
    const selectedLanguageName = useSelector((state: any) => state.selectedLanguageName);

    let renderedSlots: any[] = [];
    let products: any[] = [];
    let images: any[] = [];

    props.slots.forEach((slot: any) => {
        if (slot.type === "product-box") {
            products.push({
                id: slot.data.product.id,
                name: slot.data.product.name,
                description: HTMLReactParser(slot.data.product.description),
                price: slot.data.product.calculatedPrice.unitPrice,
                image: slot.data.product.cover.media.url,
                availableStock: slot.data.product.availableStock,
            });
        } else {
            images.push({
                id: slot.data.media.id,
                url: slot.data.media.url,
            });
        }
    });

    if (products.length === 1 && images.length === 1) {
        let product: any = products[0];
        let linkButtons: any = "";
        let linkDeactivatedButtons: any = "";
        let slotRight: any = "";
   
        linkButtons = (
            <div className="button_section">
                <div className="header_btn">
                    <div className="header_linkbtn">
                        {/* <Link
                            to={`/product/${product.id}/`}
                            className="linkbtn linkbtn__primary">
                            <span className="linkbtn__text">{translations['custom.eurobutton']}</span>
                            <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                        </Link> */}

                        <Button
                            href={`/product/${product.id}/`}
                            text={translations['custom.eurobutton']}
                        />

                    </div>
                    <div className="header_linkbtn_secondary">
                        {/* <a href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                        ? "#"
                                        : translations['custom.onchainbuttonteaserlink']}
                        className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                            <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                            <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                        </a> */}
                        <Button
                            href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'? "#" : translations['custom.onchainbuttonteaserlink']}
                            classes={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}
                            text={translations['custom.onchainbutton']}
                        />
                    </div>
                </div>
                <div className="header_linkbtn_secondary">
                    {/* <a href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                        ? "#"
                        : translations['custom.onchainbuttonteaserlink']}
                       className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                        <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </a> */}
                       <Button
                        href={translations['custom.secondaryhomebuttonteaserdisabled-nl'] == 'disabled' ? "#" : translations['custom.secondaryhomebuttonteaserlink-nl']}
                        classes={"linkbtn linkbtn__secondary " + translations['custom.secondaryhomebuttonteaserdisabled-nl']} 
                        text={translations['custom.secondaryhomebutton-nl']}
                    />
                </div>
            </div> 
        );

        linkDeactivatedButtons = (
            <div className="button_section">
                <div className="header_btn">
                    <div className="header_linkbtn">
                        {/* <a className="linkbtn linkbtn__primary disabled">
                            <span className="linkbtn__text">{translations['custom.eurobutton']}</span>
                            <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                        </a> */}
                        <Button
                            classes={"linkbtn linkbtn__primary disabled"}
                            text={translations['custom.eurobutton']}
                        />
                    </div>
                    <div className="header_linkbtn_secondary">
                        {/* <a href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                        ? "#"
                                        : translations['custom.onchainbuttonteaserlink']}
                        className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                            <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                            <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                        </a> */}
                        <Button
                            href={translations['custom.secondaryhomebuttonteaserdisabled'] == 'disabled' ? "#" : translations['custom.secondaryhomebuttonteaserlink']}
                            classes={"linkbtn linkbtn__secondary " + translations['custom.secondaryhomebuttonteaserdisabled']} 
                            text={translations['custom.secondaryhomebutton']}
                        />

                    </div>
                </div>
                <div className="header_linkbtn_secondary">
                    {/* <a href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                        ? "#"
                        : translations['custom.onchainbuttonteaserlink']}
                       className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                        <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </a> */}
                       <Button
                        href={translations['custom.secondaryhomebuttonteaserdisabled-nl'] == 'disabled' ? "#" : translations['custom.secondaryhomebuttonteaserlink-nl']}
                        classes={"linkbtn linkbtn__secondary " + translations['custom.secondaryhomebuttonteaserdisabled-nl']} 
                        text={translations['custom.secondaryhomebutton-nl']}
                    />
                </div>
            </div> 
          );

          // let linkButtonGroup = linkDeactivatedButtons;
          let linkButtonGroup = linkButtons;

          let productImage = '/crypto_home.png';
          if (product.availableStock <= 0 ){
                if(selectedLanguageName === null || selectedLanguageName === 'Deutsch'){
                    productImage = '/CS3_Stoerer_Sad_Whale.png';
                }else {
                    productImage = '/CS3_Stoerer_Sad_Whale.png';
                }                
              //linkButtonGroup = linkButtons;
          }

          slotRight = (
            <div className={"header-image-right"}>
                <Hero_Main_Right 
                    imgSM={productImage}
                    // {product.image}
                    imgMD={productImage}
                    //{product.image}
                    imgLG={productImage}
                    // {product.image}
                    imgAlt="Cryptostamp Header">
                        
                    <Headline
                        type="h1"
                        classes="heading heading--fineline-inline heading-home">
                        {translations['custom.hero.title']} {product.name}
                    </Headline>
                    {linkButtonGroup}
                </Hero_Main_Right> 
            </div>
        );

        renderedSlots.push(slotRight);

        let image: any = images[0];
        renderedSlots.push(
            <div className={"header-image"} key={image.id}>

                <Hero_Main imgSM={image.url}
                           imgMD={image.url}
                           imgLG={image.url}
                           imgAlt="Cryptostamp Header">

                    {/* <p className="hero__intro">
                                Nicht zu Hause ? Jetzt einfach Ihre Sendung umleiten.
                            </p>

                        <Linkbutton
                                classes="linkbtn linkbtn__primary"
                                href="http://www.post.at"
                                title="Link zu Post.at"
                                text="Sendung Umleiten"
                                target="_self"/>*/}
                </Hero_Main>
            </div>
        );
    }

    return (
        <div className="headerspace">
            {renderedSlots}
            {/* <AnchorLink offset={() => 80} href="#cryptocontent" className="animateArrow"><FontAwesomeIcon icon={faChevronDown}/></AnchorLink> */}
        </div>
    );
};

export default ImageBlock;