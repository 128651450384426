import "./ImageTextBlock.scss";
import HTMLReactParser from 'react-html-parser';
import Hero from "chamaeleon-react/components/Hero/Hero.tsx"
import Headline from 'chamaeleon-react/components/Headline/Headline.tsx';
import {useSelector} from "react-redux";

const ImageTextBlock: any = (props: any) => {
    let textSlot = props.slots[0];
    let imageSlot = props.slots[1];
    const translations = useSelector((state: any) => state.translations);

    return (
        <div className="crypto-banner" id="cryptocontent">
            <div className="TextBlock">
                <div className="container homelabel">
                    <h1 className="heading heading--fineline-inline">
                        <span>{translations['custom.bannertitle']}</span>
                    </h1>
                    <h3>{HTMLReactParser(textSlot.data.content)}</h3>
                </div>
            </div>
            <a href={imageSlot.data.url} target={imageSlot.data.newTab ? '_blank' : ''}>
            <Hero imgSM={imageSlot.data.media.url}
                  imgMD={imageSlot.data.media.url}
                  imgLG={imageSlot.data.media.url}
                  imgAlt="Cryptostamp Header">
            </Hero>
            </a>
        </div>
    );
};

export default ImageTextBlock;