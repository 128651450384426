import {Product} from "./InterfacesGA";
declare const window: any;

interface Impression extends Product {
    category:string,
    list:string,
    position:string
}


export const createNameString=(text:string):string=>{
    return text.substring((text.indexOf("- ")+1), text.length)
}

export const createCategoryString=(text:string):string=>{
    return "Crypto stamps" //text.substring(0, text.indexOf(" -"))
}


export const createImpressionsArray = (products:Product[]):Impression[]=>{
    let impressions=[];
    products.forEach((product,i)=>{
        impressions.push({
            name:createNameString(product.name),
            id:product.id,
            price:product.calculatedPrice.unitPrice+"",
            category:createCategoryString(product.name),
            list:"Weitere Produkte",
            position:(i+1)+""
        })

    })
    return impressions;
}


export const fireAddToCart = (product:Product, quantity:number):void=>{
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
    'event': 'addToCart',
    'ecommerce': {
    'currencyCode': 'EUR',
    'add': {
    'products': [{
    'name': createNameString(product.name),
    'id': product.id,
    'price': (quantity === 1 ? product.calculatedPrice.unitPrice : product.calculatedPrice.totalPrice as any *quantity)+"",
    'category': createCategoryString(product.name),
    'quantity': quantity+""
    }]
    }
    }
    });
}

export const fireProductClick = (product:Product,position:number):void => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
    'event': 'product_click',
    'ecommerce': {
    'currencyCode': 'EUR',
    'click': {
    'actionField': {'list': 'Weitere Produkte'},
    'products': [{
    'name': createNameString(product.name),
    'id': product.id,
    'price': product.calculatedPrice.unitPrice+"",
    'category': createCategoryString(product.name),
    'position': position+1+""
    }]
    }
    },
    'eventCallback': function() {
        // console.log(product)
    }
    });
   }

