import {createImpressionsArray} from "./Shared.service"
import {Product} from "./InterfacesGA"

declare const window: any;



export const fireProductImpression=(products:Product[]):void=>{
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
    'event': 'product_impression',
    'ecommerce': {
    'currencyCode': 'EUR',
    'impressions':createImpressionsArray(products)
    }
    });
}

