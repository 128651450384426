import "./Checkout.scss";

import React, {useEffect, useState, useContext} from "react";
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import HTMLReactParser from "react-html-parser";
import { getGAHook} from "../GoogleAnalytics/GAServiceHook"
import { StoreContext } from "../Store";
import {Button} from "@acl/design-base";
import { Link } from "react-router-dom";
import { fireCheckout } from "../GoogleAnalytics/CheckoutGA.service";

function CheckoutSuccessPage(props) {
    const {contextState, setValues} = useContext(StoreContext)
    const context = contextState.context
    const [showContent, setShowContent] = useState(true);
    const translations = contextState.translations
    const {firePurchase} = getGAHook();

    const selectedLanguageName = contextState.selectedLanguageName;

  
    
    useEffect(() => {
        const gaData = JSON.parse(sessionStorage.getItem("ga-data"));
        fireCheckout(gaData.lineItems,4)
        firePurchase(gaData.lineItems,gaData.orderNumber,gaData.shippingTotal);
        sessionStorage.removeItem("ga-data")
    }, [])

    if (showContent === false) {
        return <div className="spinner"><div></div></div>;
    }

    return (
        <div id="checkout_success">
            <div 
            id="checkout_success_top_banner"
            // className="checkout_success_top_banner"
            >
                <img src="/Checkout_Success_Banner_1920x480.png"/>
            </div>
           

            <div className="container content__container">
                <div className="row content__row">
                    <div className="col-md-12 col-lg-8 main">
                        <div className="absatz">
                            <div className="headline">
                                {/* {translations['custom.success.text1']} {orderNumber} {translations['custom.success.text2']} <span className="email">{context.customer.email}</span>. */}
                            </div>
                        </div>

                        <div className="absatz">
                            <span className="email"> {translations['custom.order-info-header-delivery']} </span>
                            {translations['custom.success.deliveryInfo.text'] }
                        </div>

                        <div className="absatz">
                        <div className="fwTeaser">
                            <div className="container">
                                <div className="row row-no-padding">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="fwTeaser__content">
                                        <div>
                                            <span
                                                className="email">{context.customer.firstName} {context.customer.lastName}</span><br/>
                                            {context.customer.defaultBillingAddress.street}<br/>
                                            {context.customer.defaultBillingAddress.zipcode} {context.customer.defaultBillingAddress.city}<br/>
                                            {context.customer.defaultBillingAddress.country.name}<br/>
                                            {context.customer.email}<br/>
                                            {context.customer.defaultBillingAddress.phoneNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="fwTeaser__image_success">
                                    <img src="/Visual.png" />
                                    {/* <img src={props.image} alt={props.imageAlt} /> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>                         
                    
                        </div>

                        <div className="absatz">
                            <h2> {translations['custom.newsletter.header'] } </h2>
                            <p> {translations['custom.newsletter.text'] } </p>
                    
                            <Button
                                href={`https://news.post.at/formview/?p=z797c1a404206096a1c54cd38a6983d96`}
                                text={translations['custom.newsletter']}
                                classes={"linkbtn linkbtn__primary"}
                            />

                            <Button
                                href={localStorage.getItem("sw_language_name") === "English" ? `/en` : `/`}
                                classes={"linkbtn linkbtn__secondary"}
                                text={translations['general.back']}
                            />
                        </div>
                    </div>

                    <div className="sidebar col-md-12 col-lg-4">
                        <div className="sidebar__container">
                            <aside role="complementary" className="sidebar__main">
                                <h2 className="heading--h2"></h2>
                                {HTMLReactParser(translations['custom.hotlineInfo'])}
                                <p><a href={translations['custom.contactFormLink'] }>{translations['custom.contactFormText'] } &gt;</a></p>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutSuccessPage;
