import Countdown, {zeroPad} from "react-countdown";
import React, {useEffect, useState, useContext} from "react";
import {batch, useDispatch, useSelector} from 'react-redux'
import CartRepository from "./CartRepository";
import Notifier from "../Helpers/Notifier";
import HTMLReactParser from 'react-html-parser';
import { StoreContext } from "../Store";

function CartLifeTime(param) {
    const { setValues, contextState} = useContext(StoreContext); 
    const [cartExpiration, setCartExpiration] = useState(null);
    const translations =  contextState.translations;
    //const cartExpiration = useSelector((state: any) => state.cartExpiration);
    const [showCountdown, setShowCountdown] = useState(false);
    const cart = contextState.cart; 
    const countdownComplete =  contextState.countdownComplete;
    const countdownAlert = contextState.countdownAlert;
    
   
    useEffect(() => {
        if (cart?.extensions?.custom?.expiredAt) {
            setCartExpiration(cart.extensions.custom.expiredAt);
            setShowCountdown(true);
            if(contextState.workflowStatus !== CartRepository.inProgress) {
             setValues({ type: "set", field: "workflowStatus", value: CartRepository.inProgress })
            }
        } else {
            setCartExpiration(null);
            setShowCountdown(false);
        }

    }, [cart]);


    function completeHandler() {
        setCartExpiration(null);
        setShowCountdown(false);
        setValues({type: 'set', field: 'cart', value: null});
        setValues({type: 'set', field: 'workflowStatus', value: CartRepository.cancelled});
        CartRepository.get().then((cart) => {
            setValues({type: 'set', field: 'cart', value: cart});
            setValues({type: 'set', field: 'workflowStatus', value: CartRepository.cancelled});
        });
    }

    function countdownTick() {
          
    }

    function renderCountdown() {
        if (cartExpiration > 0) {
            const renderer = ({minutes, seconds, completed }) => {
                if (!completed) {
                   
                    if (minutes === 2 && seconds === 0 && countdownAlert === null) {
                       batch(() => {
                          setValues({type: 'set', field: 'countdownAlert', value: true});
                       });
                        
                    }
                
                    return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                } else {
                    if (countdownComplete === null) {
                        batch(() => {
                            setValues({ type: "set", field: "countdownComplete", value: true });
                        });
                    }

                    return "";
                }
            };

            return (
                <Countdown onComplete={() => completeHandler()} onTick={() => countdownTick()} date={ cartExpiration*1000} renderer={renderer}/>
                );
        }
    }

    const CountdownElement = renderCountdown()
   
    
    if (showCountdown) {
        return <span>{CountdownElement}</span>
    }
    return <span></span>;

}

export default CartLifeTime;


