import "./Cart.scss";

import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import Currency from "../Helpers/Currency";
import { StoreContext } from "../Store";

function MiniCart() {
    const { contextState} = useContext(StoreContext)

    const translations = contextState.translations;
    const cart = contextState.cart;
    const selectedLanguageName = contextState.selectedLanguageName;     
    

    const itemLines = [];
    let summaryLine = <React.Fragment></React.Fragment>;
    let shippingLine = <React.Fragment></React.Fragment>;
    if (cart) {
        shippingLine =
            <React.Fragment>
                <div className="line-row-total">
                    <div className="left-aligned"> {translations['account.orderItemShippingcosts']} </div>
                    <div className="right-aligned">{Currency.format(cart?.deliveries[0]?.shippingCosts?.totalPrice) || '-'}</div>
                </div>
            </React.Fragment>;

        summaryLine =
            <React.Fragment>
                <div className="line-total">
                    <div className="left-aligned">{translations['account.orderItemTotal'] }</div>
                    <div className="right-aligned">{Currency.format(cart.price.totalPrice)}</div>
                </div>
            </React.Fragment>;

        cart.lineItems.forEach((item: any) => {
            itemLines.push(
                <div className="line-row" key={item.id}>
                    {/* TODO - SEHR DIRTY LÖSUNG FÜR PRE-SALE */}
                    <div className="left-aligned"> {item.quantity} x {item.label === 'Crypto stamp 3.1 - Katze' && selectedLanguageName === 'English' ? 'Crypto stamp 3.1 - Cat' : item.label } </div>
                    <div className="right-aligned">{Currency.format(item.quantity*item.price.unitPrice)}</div>
                </div>
            );
        });
    }

    return (
        <aside className="sidebar__top">
            <div className="react-priceinfo" data-formtype="priceinfo">
                <div className="priceinfo">
                    <h3 className="title">{translations['custom.product.selection.text']}</h3>
                    {itemLines}
                    {shippingLine}
                    {summaryLine}
                </div>
            </div>
        </aside>
    );
}

export default MiniCart;