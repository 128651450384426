import "./ImageTextBlock.scss";
import HTMLReactParser from 'react-html-parser';
import Hero from "chamaeleon-react/components/Hero/Hero.tsx"
import Headline from 'chamaeleon-react/components/Headline/Headline.tsx';

const BannerBlock: any = (props: any) => {
    let headlineSlot = props.slots.find(e => e.type === 'plaintext');
    let textSlot = props.slots.find(e => e.type === 'text');
    let imageSlot = props.slots.find(e => e.type === 'image');

    return (
        <div className="crypto-banner" id="cryptocontent">
            <div className="TextBlock">
                <div className="container homelabel">
                    <h1 className="heading heading--fineline-inline">
                        <span>{headlineSlot.config.content.value}</span>
                    </h1>
                    <h3>{HTMLReactParser(textSlot.data.content)}</h3>
                </div>
            </div>
            <a href={imageSlot.data.url} target={imageSlot.data.newTab ? '_blank' : ''}>
                <Hero imgSM={imageSlot.data.media.url}
                      imgMD={imageSlot.data.media.url}
                      imgLG={imageSlot.data.media.url}
                      imgAlt="Cryptostamp Header">
                </Hero>
            </a>
        </div>
    );
};

export default BannerBlock;