import ShopwareAPI from '../ShopwareAPI/ShopwareAPI';

class CategoryRepository {
    private static rootCategory = 'Home';

    public static async products(navigationId: string) {
        let api = new ShopwareAPI();
        let d = new Date();
        let currentTimestamp = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() +  '.' + d.getMilliseconds();
        return api.write('/product-listing/' + navigationId, {
            "filter": [
                {
                    "type": "range",
                    "field": "createdAt",
                    "parameters": {
                        "lt": currentTimestamp
                    }
                }
            ]
        })
            .then((response: any) => {
                if (response && response.elements) {
                    return response.elements;
                }
            });
    }

    public static async getCategoryMedia(categoryId: string) {
        let api = new ShopwareAPI();

        return api.write('/category/' + categoryId, {
            "filter": [
                {
                    "type": "range",
                    "field": "createdAt"
                }
            ]
        })
            .then((response: any) => {
                if (response && response.media) {
                    return response.media;
                }
            });
    }

    public static async categoryDetails(navigationId: string) {
        let api = new ShopwareAPI();
        return api.write('/category/' + navigationId, { })
            .then((response: any) => {
                if (response && response) {
                    return response;
                }
            });
    }

    public static async getCategoryMediaById(mediaId: string) {
        let api = new ShopwareAPI();
        return api.fetch('/media/' + mediaId , 'GET')
            .then((response: any) => {
                if (response && response) {
                    return response;
                }
            });
    }

    public static getCurrentCategoryId(category: string, navigation) {
        const rootCategory = navigation.find(nav => {
            return nav.name === CategoryRepository.rootCategory && nav.level === 1
        });
        const secondLevelCategory = rootCategory.children.find(nav => {
            return nav.name === category && nav.level === 2
        });
        return secondLevelCategory.id;
    }
}

export default CategoryRepository;