import HTMLReactParser from "react-html-parser";
import "./ProductSlider.scss";
import { Link } from "react-router-dom";
import TeaserFullWidthProductSlider from "chamaeleon-react/components/TeaserFullWidth/TeaserFullWidthProductSlider";
import TeaserList from "chamaeleon-react/components/TeaserFullWidth/TeaserList";
import Card from "chamaeleon-react/components/Card/Card.tsx";
import CardBody from "chamaeleon-react/components/Card/CardBody";
import CardTeaserContent from "chamaeleon-react/components/Card/CardTeaserContent";
import CardHeadImage from "chamaeleon-react/components/Card/CardHeadImage";
import {useDispatch, useSelector} from "react-redux";
import CartRepository from "../Cart/CartRepository";
import { Button } from "@acl/design-base";

function ProductSlider(props) {
  const dispatch = useDispatch();
  const translations = useSelector((state: any) => state.translations);
  const selectedLanguageName = useSelector((state: any) => state.selectedLanguageName);

  function addToCart(productId: string, quantity: number = 1) {
    dispatch({ type: "set", field: "workflowStatus", value: null })
    CartRepository.add(productId, quantity).then((cart: any) => {
      dispatch({ type: "set", field: "cart", value: cart });
    });
    props.setDisplayHeaderModal(true);
  }

  let renderedSlots: any[] = [];
  let preRenderedSlots: any[] = [];
  let products: any[] = [];
  let title = null;
  let src = null;

  if (props.slots[0].config.title.value) {
    title = props.slots[0].config.title.value;
  }

  props.slots.forEach((slot: any) => {
    slot.data.products.forEach((product: any) => {
      products.push({
        id: product.id,
        name: product.name,
        description: HTMLReactParser(product.description),
        price: product.calculatedPrice.unitPrice,
        image: product.cover.media.url,
        availableStock: product.availableStock,
      });
    });
  });

  if (products.length > 1) {
    products.forEach((product: any) => {
      let linkButtons: any = "";
      if (product.availableStock > 0) {
        linkButtons = (
          // <Link
          //   to={`/cart`}
          //   onClick={() => addToCart(product.id)}
          //   className="linkbtn linkbtn__primary">
          //   <span className="linkbtn__text">{translations['custom.eurobutton']}</span>
          //   <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
          // </Link>
          <Button
            	href={`/cart`}
              onClickFn={() => addToCart(product.id)}
              text={translations['custom.eurobutton']}
          />
        );
      }
      preRenderedSlots.push(
        <div className="col-lg-4 col-md-6 col-sm-12" key={product.id}>
          <Card type="teaser">
            <CardHeadImage
              headline={product.name}
              headline_white={true}
              src_lg={product.image}
              src_md={product.image}
              src_sm={product.image}
              alt="example alt text"
              lazy={false}
            />
            <CardBody>
              <CardTeaserContent
                headline={product.name}
                text={"€ " + product.price}
              />
              {linkButtons}
              <div className="fwTeaser__content__richtext productdetails_onchain">
                <ul className="checkmarks">
                  {translations['custom.onchainbuttonteaser']}
                </ul>
              </div>
              {/* <a href = {translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                    ? "#"
                                    : translations['custom.onchainbuttonteaserlink']}
                className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
              </a> */}
              <Button
                href = {translations['custom.onchainbuttonteaserdisabled'] == 'disabled' ? "#" : translations['custom.onchainbuttonteaserlink']}
                classes={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}              
                text={translations['custom.onchainbutton']}
                />
            </CardBody>
          </Card>
        </div>
      );
    });
    renderedSlots.push(<div className="row">{preRenderedSlots}</div>);
  }
  if (products.length === 1) {
    let product: any = products[0];

    let linkButtons: any = "";

    //if (product.availableStock > 0) {
      linkButtons = (
        // <Link
        //   to={`/product/${product.id}/`}
        //   className="linkbtn linkbtn__primary">
        //   <span className="linkbtn__text">{translations['custom.eurobutton']}</span>
        //   <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
        // </Link>
        <Button
            href={`/product/${product.id}/`}
            text={translations['custom.eurobutton']}
        />
      );
    /* } else {
      linkButtons = (
        <Link to={`/cart`} className="linkbtn linkbtn__primary disabled">
          <span className="linkbtn__text">Zum Produkt</span>
          <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
        </Link>
      );
    } */

    if(selectedLanguageName === null || selectedLanguageName === 'Deutsch'){
      src = "/Artikelbild_CS3_NFC.png";
    }else{
      src = "/Artikelbild_CS3_NFC_EN.png";
    }
    
    renderedSlots.push(
      <TeaserFullWidthProductSlider image={product.image} imageAlt={product.name} key={product.id} src={src}>
        <h1 className="heading heading--fineline-inline">
            <span>{product.name}</span>
        </h1>

        <TeaserList>{product.description}</TeaserList>
        {linkButtons}
        <div className="fwTeaser__content__richtext productdetails productdetails_onchain">
          <ul className="checkmarks">
            {translations['custom.onchainbuttonteaser']}
          </ul>
        </div>
        {/* <a href = {translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                    ? "#"
                                    : translations['custom.onchainbuttonteaserlink']}
          className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
          <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
          <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
        </a> */}
        <Button
          href= {translations['custom.onchainbuttonteaserdisabled'] == 'disabled' ? "#" : translations['custom.onchainbuttonteaserlink']}
          classes = {"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}
          text={translations['custom.onchainbutton']}
        />
      </TeaserFullWidthProductSlider>
    );
  }

  return (
    <div className="ProductSliderBlock">
      <div className="ProductSliderBlock_Title">{title}</div>
      {renderedSlots}
      <div className="clear"></div>
    </div>
  );
}

export default ProductSlider;
