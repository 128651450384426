import "./Countdown.scss";

import React, {useEffect, useState} from "react";
import moment from "moment";
import Countdown from "react-countdown";

function Countdowner(props) {

    const finaleDate = parseInt(process.env.REACT_APP_LIVE_TIME_STAMP) * 1000;
    const currentDateTime = parseInt(props.unixTimeStamp) * 1000;
    const diff =  finaleDate - currentDateTime;

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            window.location.reload();
        } else {
            // Render a countdown
            return (
                <span>
        {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
      </span>
            );
        }
    };

    return (
        <article className="countdown">
            <div className="countdown_center_image">
                <p>Seite wird automatisch aktualisiert - bitte nicht auf Aktualisieren klicken</p>
                <p>Page refreshes automatically - please do not press refresh</p>
                <img src="logo_cryptostamp.png"/>
            </div>
            <div className="container-home">
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="countdown">
                        {<Countdown date={currentDateTime + diff} renderer={renderer} />}
                </div>
                </div>
                <div className="alert">
                    Starting soon...
                </div>
            </div>
        </article>
    );
}

export default Countdowner;
