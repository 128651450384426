import ShopwareAPI from '../ShopwareAPI/ShopwareAPI';

class CartRepository {
    public static inProgress : string = 'in-progress';
    public static completed : string = 'completed';
    public static cancelled : string = 'cancelled';
    public static failed : string = 'failed';

    public static expiredTime: string = '';

    public static async get() {
        let api = new ShopwareAPI();
        return api.fetch('/checkout/custom-cart')
            .then((response: any) => {
                return response;
            });
    }

    /**
     * @deprecated
     */
    public static async reloadCart() {
        return CartRepository.get();
    }

    public static async checkItemInCart(productId: string){
        var updateId = [];
        return this.get().then((cart) => {
            if (cart) {
                cart.lineItems.forEach(elements => {
                    if (elements.referencedId === productId) {
                        updateId = [elements.id, elements.quantity];
                    }
                });
            }
            return updateId;
        });
    }

    public static async add(productId: string, quantity: number = 1) {
        return this.checkItemInCart(productId).then((returnVal) => {
            if (returnVal && returnVal.length > 1) {
                return this.update(returnVal[0], Number(returnVal[1]) + Number(quantity));
            } else {
                return this.addItem(productId, Number(quantity));
            }
        });
    }

    public static async addItem(productId: string, quantity: number = 1){
        let api = new ShopwareAPI();
        return api.write('/checkout/cart/custom-line-item', {
            items: [
                {
                    type: 'product',
                    referencedId: productId,
                    quantity: quantity
                }
            ]
        }).then((data: any) => {
            return data;
        });
    }

    public static async delete(itemId: string) {
        let api = new ShopwareAPI();
        return api.write('/checkout/cart/line-item', {
            data: { ids: [itemId] }
        }, 'DELETE').then((data: any) => {
            return data;
        });
    }

    public static async update(itemId: string, quantity: number = 1) {
        let api = new ShopwareAPI();
        return api.write('/checkout/cart/custom-line-item', {
            items: [
                {
                    id: itemId,
                    quantity: quantity
                }
            ]
        }, 'PATCH').then((data: any) => {
            return data;
        });
    }
}

export default CartRepository;
