import {createNameString,createCategoryString} from "./Shared.service"
import {Item,ModifiedItem} from "./InterfacesGA";

declare const window: any;

const createProductCheckoutArray = (items:Item[]):ModifiedItem[] =>{
  let checkoutArray= [];
  items.forEach(
    item=> {
      checkoutArray.push(
        {
          name: createNameString(item.label),
          id:item.id,
          price: (item.price?.totalPrice || item.totalPrice) +"",
          category:createCategoryString(item.label),
          quantity: item.quantity+"",
        }
      )
    }
  )
  return checkoutArray;
}


export const fireCheckout = (items:Item[], step:number=1):void => {
    window.dataLayer.push({ ecommerce: null });
    if(step < 4 ) {
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
        'checkout': {
        'actionField': {'step': step},
        'products': createProductCheckoutArray(items)
        }
        }
        })
    }
    else {
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
        'checkout': {
        'actionField': {'step': step, 'option': "Standardvalue"},
        'products': createProductCheckoutArray(items)
        }
        }
        })
    }
   }

