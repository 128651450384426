import "./Cart.scss";

import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import CartRepository from "./CartRepository";
import Currency from "../Helpers/Currency";
import CartLifeTime from "./CartLifeTime";
import { getGAHook } from "../GoogleAnalytics/GAServiceHook";
import { StoreContext } from "../Store";
import {Button} from "@acl/design-base"


function Cart({ setDisplayHeaderModal }, type?) {
  const { contextState, setValues} = useContext(StoreContext)
  let carttype = type ? type : null;
  const cart = contextState.cart;
  const [showContent, setShowContent] = useState(false);
  const workflowStatus = contextState.workflowStatus;
  const translations = contextState.translations;
  const countdownAlert = contextState.countdownAlert;
  const selectedLanguageName = contextState.selectedLanguageName;
  let history = useHistory();



  const goToPreviousPath = () => {
    const targetPath = ( selectedLanguageName === "English" ? 'home/Crypto%20stamps/' : 'home/Crypto%20stamps/')
    history.push(targetPath);
  };

  const {fireRemoveAllFromCart, fireRemoveOneFromCart, fireAddItemToCart} = getGAHook();

  

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayHeaderModal(false);
    }, 5000);

    return () => {
      setDisplayHeaderModal(false);
      clearTimeout(timer);
    };
  }, [setDisplayHeaderModal]);

   useEffect(() => {
     setShowContent(workflowStatus === null ? false : true);
   }, [workflowStatus]);

  const saveCartShippingInfos = (cart)=>{
    if(sessionStorage.getItem("itemsShippingInfo")){
      sessionStorage.removeItem("itemsShippingInfo")
    }
    const itemsShippingInfo= {
      items:cart.lineItems,
      shipping:cart?.deliveries[0]?.shippingCosts?.totalPrice
    }
    sessionStorage.setItem("itemsShippingInfo",JSON.stringify(itemsShippingInfo));
  }


  if (showContent === false) {
    return <div className="spinner">
      <div></div>
    </div>;
  }

  function nextDisabled() {
    if (cart && cart.lineItems.length) {
      return "";
    }

    return "disabled";
  }

  function fullStockDisabled() {
    if (cart && cart.extensions.custom != undefined && cart.customFields?.customAvailableStockFull == true) {
      return "";
    }

    return "disabled";
  }

  function emptyCartMessage() {
    if (cart && !cart.lineItems.length) {
      if (workflowStatus === CartRepository.cancelled) {
        return (
            <h4 className="heading heading--h4">
              {translations['custom.cartinfo.out']}
            </h4>
        );
      } else {
        return (
            <h4 className="heading heading--h4">
              {translations['custom.cartinfo']}
            </h4>
        );
      }
    }
  }

  function cartSummary() {
    if (cart && !cart.lineItems.length) {
      return "";
    }

    return (
        <div className="cart">
          <div className="cart__cart-total">
            <div className="cart__cart-total-row total flexspace">
              <div className="left-aligned">{translations['account.orderItemTotal']}</div>
              <div className="right-aligned">
                <span>{Currency.format(cart ? cart.price.totalPrice : 0)}</span>
              </div>
            </div>
          </div>
        </div>
    );
  }

  function cartShipping() {
    if (cart && !cart.lineItems.length) {
      return "";
    }

    return (
        <div className="cart">
          <div className="cart__cart-total">
            <div className="cart__cart-total-row total flexspace">
              <div className="left-aligned">{translations['account.orderItemShippingcosts']}</div>
              <div className="right-aligned shipping">
                <span>{Currency.format(cart?.deliveries[0]?.shippingCosts?.totalPrice) || '-'}</span>
              </div>
            </div>
          </div>
        </div>
    );
  }

  function deleteItem(itemId: string) {
    CartRepository.delete(itemId).then((cart) => {
      CartRepository.get().then((cart) => {
        if (cart) {
          if (!cart?.lineItems?.length) {
            setValues({type: "set", field: "countdownComplete", value: false});
            setValues({type: "set", field: "countdown", value: null});
            setValues({type: "set", field: "countdownAlert", value: null});
          }

          setValues({type: "set", field: "cart", value: cart});
        }
      });
    });
  }

  function updateItem(itemId: string, quantity: number) {
    if (quantity < 1) {
      return;
    }
    CartRepository.update(itemId, quantity).then((cart) => {
      if (cart) {
        setValues({ type: "set", field: "cart", value: cart });
      }
    });
  }

  function renderCountdown() {
    let cartLifeTime = <CartLifeTime />;

    if (cartLifeTime === <span></span>) {
      return <React.Fragment></React.Fragment>;
    }

    return <div className={"notification " + nextDisabled()}>
      <div className="notification__icon__box">
                      <span
                          className={`notification__icon posticon_Posticon-Information`}
                          aria-hidden="true"></span>
      </div>
      <div className="notifications__container">
        <div className="notification__text">
          {translations['custom.cart.notification'] } {cartLifeTime} {translations['custom.cart.notification.minutes'] }
        </div>
      </div>
    </div>
  }

  const itemLines = [];
  if (cart) {
    cart.lineItems.forEach((item: any) => {
      itemLines.push(
        <div className="bestellposition-cart__order" key={item.id}>
          <div className="bestellposition-cart__container">
            <div className="bestellposition-cart__detail">
              <div className="bestellposition-cart__product-name">
              <a href={`/product/${item.referencedId}/`}> 
                  {item.label === 'Crypto stamp 3.1 - Katze' && selectedLanguageName === 'English' ? 'Crypto stamp 3.1 - Cat' : item.label }
              </a>
              </div>
            </div>

            <div className="bestellposition-cart__controls-desktop">
              <div className="bestellposition-cart__controls-desktop-left">
                <div className="bestellposition-cart__quanitity">
                  <button
                    type="button"
                    className="bestellposition-cart__minus"
                    onClick={() => {
                      if(item.quantity > 1 ) {
                        updateItem(item.id, --item.quantity);
                        fireRemoveOneFromCart(item);
                      }
                    }}>
                    <span className="posticon_Posticon-Standortfinder-Zoom-Minus"></span>
                  </button>
                  <div className="bestellposition-cart__quanitity-quantity">
                    {item.quantity}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      updateItem(item.id, ++item.quantity);
                      fireAddItemToCart(item);
                    }}
                    className="bestellposition-cart__plus">
                    <span className="posticon_Posticon-Standortfinder-Zoom-Plus"></span>
                  </button>
                </div>
              </div>
              <div className="bestellposition-cart__price">
                <div className="bestellposition-cart__product-price">
                  {Currency.format(item.price.totalPrice)}
                </div>
              </div>
              <div className="bestellposition-cart__controls-desktop-right">
                <span>
                  <a
                    onClick={() => {
                      deleteItem(item.id);
                      fireRemoveAllFromCart(item);
                    }}
                    className="posticon_Posticon-Papierkorb-Trash"
                  />
                </span>
              </div>
            </div>
            <div className="bestellposition-cart__controls-mobile">
              <div className="bestellposition-cart__controls-mobile-left">
                <div className="bestellposition-cart__quanitity">
                  <button
                    type="button"
                    className="bestellposition-cart__minus"
                    onClick={() => {
                      updateItem(item.id, --item.quantity);
                      if(item.quantity >= 1 ) {
                        fireRemoveOneFromCart(item);
                      }
                    }}>
                    <span className="posticon_Posticon-Standortfinder-Zoom-Minus"></span>
                  </button>
                  <div className="bestellposition-cart__quanitity-quantity">
                    {item.quantity}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      updateItem(item.id, ++item.quantity);
                      fireAddItemToCart(item);
                    }}
                    className="bestellposition-cart__plus">
                    <span className="posticon_Posticon-Standortfinder-Zoom-Plus"></span>
                  </button>
                </div>
              </div>
              <div className="bestellposition-cart__price">
                <div className="bestellposition-cart__product-price">
                  {Currency.format(item.price.totalPrice)}
                </div>
              </div>
              <div className="bestellposition-cart__controls-mobile-right">
                <span>
                  <a
                    onClick={() => {
                      deleteItem(item.id);
                      fireRemoveAllFromCart(item);
                    }}
                    className="posticon_Posticon-Papierkorb-Trash"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderElement() {
    if (carttype === "summary") {
      return (
        <div className="container content__container">
          <div className="row content__row">
            <div className="col-md-12 col-lg-8">
              <div className="centerstage">
                <main role="main" className="main">
                  <h1 className="heading--h2"> {translations['custom.checkout.cart.text'] } </h1>
                  {emptyCartMessage()}
                  <div className="bestellposition-cart">{itemLines}</div>
                  {cartShipping()}
                  {cartSummary()}
                </main>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container content__container">
          <div className="row content__row">
            <div className="col-md-12 col-lg-8">
              <div className="centerstage">
                <main role="main" className="main">
                  {/* <Notification icon="posticon_Posticon-Information" text={[]}  /> */}

                  {renderCountdown()}

                  {/* <div className={"notification reserved " + fullStockDisabled()}>
                    <div className="notification__icon__box">
                      <span
                        className={`notification__icon posticon_Posticon-Information`}
                        aria-hidden="true"></span>
                    </div>
                    <div className="notifications__container">
                      <div className="notification__text">
                        {translations['custom.product.partialreserved'] }
                      </div>
                    </div>
                  </div> */}

                  <h1 className="heading--h2"></h1>
                  <h1 className="heading--h2"> {translations['checkout.cartHeader'] } </h1>
                  {emptyCartMessage()}
                  <div className="bestellposition-cart">{itemLines}</div>
                  {cartShipping()}
                  {cartSummary()}
                </main>
              </div>
            </div>

            <div className="sidebar col-md-12 col-lg-4">
              <div className="sidebar__container">
                <aside role="complementary" className="sidebar__main">
                  <div>
                      <Link
                      onClick={()=>{
                        saveCartShippingInfos(cart);
                      }}
                      to={{
                        pathname: (selectedLanguageName === "English" ? `/en/checkout/shipment-infos`: `/checkout/shipment-infos`),
                        state: {
                          items:cart?.lineItems,
                          shipping:cart?.deliveries[0]?.shippingCosts?.totalPrice
                        },
                      }}
                      className={"linkbtn linkbtn__primary " + nextDisabled()}>
                      <span className="linkbtn__text"> {translations['custom.cart.checkoutButton.text'] } </span>
                      <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </Link>
                    
                    <Button
                      text={translations['general.back']}
                      href={translations['general.backlinkdisabled'] == 'disabled'
                      ? "#"
                      : translations['general.backlink']}
                      classes={"linkbtn linkbtn__secondary" + translations['general.backlinkdisabled']}
                      icon={"linkbtn__icon posticon_Posticon-Pfeil-links"}
                    />
                  </div>
                  <div></div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-5 col-lg-12">
                        <h3 className="heading heading--h4">
                          {translations['custom.order-info-header']}
                        </h3>
                        <p className="zahlung-und-versand-info__content">
                          {translations['custom.order-info-text']}
                          {" "}
                        </p><br />
                        <p className="zahlung-und-versand-info__content">
                          {translations['custom.order-info-text-tax']}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-5 col-lg-12">
                        <h3 className="heading heading--h4">
                          {translations['custom.order-info-header-delivery']}
                        </h3>
                        <p className="zahlung-und-versand-info__content">
                          {translations['custom.order-info-text-delivery']}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-5 col-lg-12">
                        <h3 className="heading heading--h4">
                          {translations['custom.order-info-header-payment'] } 
                        </h3>
                        <p className="zahlung-und-versand-info__content">
                          {translations['custom.order-info-text-payment'] } 
                        </p>
                        <div className="zahlung-und-versand-info__images">
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/Klarna.svg"></img>
                          </div>
                          <br />
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/mc.svg"></img>
                          </div>
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/visa.svg"></img>
                          </div>
                          {/*  
                          <br />
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/DCI.svg"></img>
                          </div>
                          */}
                        </div>
                      </div>
                      <div className="col-12 offset-md-1 col-md-5 col-lg-12 offset-lg-0 panel-right"></div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return <div className="headerspace">{renderElement()}</div>;
}

export default Cart;
