import "./../Checkout.scss";

import React, {useEffect, useState, useContext} from "react";
import {useHistory} from "react-router-dom";


import CheckoutRepository from "../CheckoutRepository";
import CartRepository from "../../Cart/CartRepository";
import ShopwareAPI from "../../ShopwareAPI/ShopwareAPI";
import { StoreContext } from "../../Store";
import { fireCheckout } from "../../GoogleAnalytics/CheckoutGA.service";



function CheckoutCommit(props) {
    const { contextState, setValues } = useContext(StoreContext)
    const [state, setState] = useState({
        checkoutStatus: null,
        isLoading: false,
        redirectUrl: null,
    });
    const {selectedLanguageName} = contextState;

    


    const history = useHistory();

    const checkout = contextState.checkout

    useEffect(() => {
        const gaData = JSON.parse(sessionStorage.getItem("ga-data"));
        fireCheckout(gaData.items,3);
      }, [])

    useEffect(() => {
        setState({...state, isLoading: true});
        if (state.isLoading) {
            return;
        }

        let addressValue = checkout.address.street + " " +
            checkout.address.housenumber +
            (checkout.address.staircase ? "/" + checkout.address.staircase : "") +
            (checkout.address.doornumber ? "/" + checkout.address.doornumber : "");

        const address = {
            title: checkout.address.title,
            salutationId: checkout.address.salutationId,
            firstName: checkout.address.firstname,
            lastName: checkout.address.lastname,
            street: addressValue,
            zipcode: checkout.address.areacode,
            city: checkout.address.city,
            countryId: checkout.address.countryId,
            phoneNumber: checkout.address.phoneNumber,
        };

        let shippingAddressValue = checkout.shippingAddress.street + " " +
            checkout.shippingAddress.housenumber +
            (checkout.shippingAddress.staircase ? "/" + checkout.shippingAddress.staircase : "") +
            (checkout.shippingAddress.doornumber ? "/" + checkout.shippingAddress.doornumber : "");
        const shippingAddress = {
            title: checkout.shippingAddress.title,
            salutationId: checkout.shippingAddress.salutationId,
            firstName: checkout.shippingAddress.firstname,
            lastName: checkout.shippingAddress.lastname,
            street: shippingAddressValue,
            zipcode: checkout.shippingAddress.areacode,
            city: checkout.shippingAddress.city,
            countryId: checkout.shippingAddress.countryId,
            phoneNumber: checkout.shippingAddress.phoneNumber,
        };

        const customer = {
            title: checkout.address.title,
            salutationId: checkout.address.salutationId,
            firstName: checkout.address.firstname,
            lastName: checkout.address.lastname,
            birthdayDay: checkout.address.birthdayDay,
            birthdayMonth: checkout.address.birthdayMonth,
            birthdayYear: checkout.address.birthdayYear,
            newsletter: checkout.address.newsletter,
            storefrontUrl: "https://localhost",
            email: checkout.address.email,
            billingAddress: address,
            shippingAddress: shippingAddress,
            customFields: contextState.context.customer?.customFields
        };

        CheckoutRepository.order(
            customer,
            checkout.paymentMethodId,
            checkout.shippingMethodId,
            contextState.selectedLanguageName
        )
            .then((payment) => {
                if (payment && payment.redirectUrl) {
                    setValues({type: 'set', field: 'cart', value: null});
                    setState({
                        ...state,
                        checkoutStatus: true,
                        redirectUrl: payment.redirectUrl,
                    });
                    return;
                }
                setState({...state, checkoutStatus: false});
                history.push('/checkout/summary');
            })
            .catch((reason: any) => {
                setState({...state, checkoutStatus: false});
                history.push('/checkout/summary');
            });
    }, []);

    function checkoutStatus() {
        if (state.checkoutStatus === true) {
            return <iframe src={state.redirectUrl} title="payment-frame"></iframe>;
        }

        /* else if (state.checkoutStatus === false) {
          return <b>Bestellung fehlgeschlagen.</b>;
        } */

        return <div className="spinner">
            <div></div>
        </div>;
    }

    return <div>{checkoutStatus()}</div>;
}

export default CheckoutCommit;
