import "./TextBlock.scss";
import HTMLReactParser from 'react-html-parser';
import {useSelector} from "react-redux";
import {useEffect} from "react";

// const SimpleLightbox = require('simple-lightbox');

const TextBlock: any = (props: any) => {
    let renderedSlots: any[] = [];
    const translations = useSelector((state: any) => state.translations);

    useEffect(() => {
        // let nodes = document.querySelectorAll('#block_' + props?.block?._uniqueIdentifier + ' a[href="#img"]');
        // for (let i = 0; i < nodes.length; i++) {
        //     nodes[i].setAttribute('href', props?.block?.backgroundMedia?.url);
        //     nodes[i].setAttribute('data-type', 'lightbox');
        // }
        // let lightbox = new SimpleLightbox({
        //     elements: '#block_' + props?.block?._uniqueIdentifier + ' a[data-type="lightbox"]',
        // });

    }, []);

    props.slots.forEach((slot: any) => {
        renderedSlots.push(HTMLReactParser(slot.data.content));
    });


    return (
        <div id={'block_' + props?.block?._uniqueIdentifier} className={ 'TextBlock ' + props?.block?.cssClass }>
            <div className="container homelabel">
                <h1 className="heading heading--fineline-inline">
                    <span>{translations['custom.bannertitletext']}</span>
                </h1>
                <h3>{renderedSlots}</h3>
            </div>
        </div>
    );
};

export default TextBlock;