import "chamaeleon/styles/Styles.scss";
import "./App.scss";
import './Toaster.scss';
import TagManager from 'react-gtm-module'

import { StoreContext } from "./Store";
import {useEffect, useState, useContext} from "react";


import ShopwareAPI from "./ShopwareAPI/ShopwareAPI";
import CartRepository from "./Cart/CartRepository";
import moment from "moment";

import Countdown from "./Countdowner";

import { StoreContextProvider } from "./Store";
import AppRouter from "./Router";

const tagManagerArgs = {
    gtmId: "GTM-KM8QGJ"
}

TagManager.initialize(tagManagerArgs)

const Loader = function (props) {

    const { setValues,contextState } = useContext(StoreContext);

    useEffect(() => {
        let api = new ShopwareAPI();
            Promise.all([
            api.fetch('/static-data', 'GET'),
            api.fetch('/dynamic-data', 'GET'),
            api.fetch("/time-zone", "GET"),
        ]).then((data) => {
            setValues({type: 'setStaticData', value: data[0]});
            let cart = data[1].cart;
            if (cart?.extensions?.custom?.expiredAt) {
                setValues({type: "set", field: "workflowStatus", value: CartRepository.inProgress})
            }
            props.setUnix_timestamp(data[2].unix_timestamp);
            setValues({type: 'set', field: 'cart', value: cart});
            window.localStorage.setItem('sw_context_token', data[1].context.token);
            setValues({type: 'setContext', field: 'context', value: data[1].context});
            setValues({type: 'set', field: 'newsletter', value: data[1].newsletter});
            props.setLoading(false);
        });
    }, []);

    return (
        <div>
            <div className="spinner">
                <div></div>
            </div>
        </div>
    );
};

function App() {
    const [maintanence, setMaintanence] = useState(false);
    const [ENLang, setENLang] = useState(false);
    const [DELang, setDELang] = useState(false);
    const [countdown, setCountdown] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let __html_de = require('./maintanence-DE.html.js');
    let __html_en = require('./maintanence-EN.html.js');
    let maintenanceTemplateEN = {__html: __html_en};
    let maintenanceTemplateDE = {__html: __html_de};
    const [unix_timestamp, setUnix_timestamp] = useState(null);

    useEffect(() => {
        if (unix_timestamp) {
            //setCountdown(true);
        }
        if (process.env.REACT_APP_COUNTDOWN_TIME_STAMP && process.env.REACT_APP_LIVE_TIME_STAMP && unix_timestamp) {
            let countdownDate = moment.unix(parseInt(process.env.REACT_APP_COUNTDOWN_TIME_STAMP));
            let releaseDate = moment.unix(parseInt(process.env.REACT_APP_LIVE_TIME_STAMP));
            let serverDate = moment.unix(parseInt(unix_timestamp));

            if (serverDate.isSameOrBefore(releaseDate) && serverDate.isSameOrAfter(countdownDate)) {
                setCountdown(true);
                return () => {
                    <Countdown unixTimeStamp={unix_timestamp} />
                }
            }

            if (urlParams.has('countdown') && urlParams.get('countdown') === 'acl-only') {
                setCountdown(true);
            }

            if (process.env.REACT_APP_LIVE_TIME_STAMP) {

                let releaseDate = moment.unix(parseInt(process.env.REACT_APP_LIVE_TIME_STAMP));
                if (serverDate.isSameOrBefore(releaseDate)) {
                    setMaintanence(true);
                    if (window.location.pathname.toLowerCase().includes('/en')) {
                        setENLang(true);
                    } else {
                        setDELang(true);
                    }
                }

                if (urlParams.has('maintenance') && urlParams.get('maintenance') === 'acl-only') {
                    setMaintanence(false);
                }
            }

        }

    }, [unix_timestamp]);



    if (urlParams.has('code')) {
        let api = new ShopwareAPI();
        let code = urlParams.get('code');
        api.write('/oauth/code', {code: code}).then(response => {
            window.localStorage.removeItem('VersandInformationen');

            let savedUri = window.localStorage.getItem('redirectTo');
            window.localStorage.removeItem('redirectTo');
            document.location.href = (savedUri || '/');
        });
        return (
            <div>
                <div className="spinner">
                    <div></div>
                </div>
            </div>
        );
    }

    if (maintanence) {
        if (ENLang) {
            return (
                <div className="screen-share">
                    <span dangerouslySetInnerHTML={maintenanceTemplateEN}/>
                </div>
            );
        } else {
            return (
                <div className="screen-share">
                    <span dangerouslySetInnerHTML={maintenanceTemplateDE}/>
                </div>
            );
        }

    }


    if (countdown) {
        return (
            <Countdown unixTimeStamp={unix_timestamp} />
        );
    }

    return (
            <StoreContextProvider>
                { isLoading ? <Loader setLoading={setLoading} setUnix_timestamp={setUnix_timestamp} /> : <AppRouter /> }
            </StoreContextProvider>
    );
}

export default App;
