import {createNameString,createCategoryString} from "./Shared.service"
import {Item, ModifiedItem} from "./InterfacesGA"

declare const window: any;

const createProductPurchaseArray = (items:Item[]):ModifiedItem[] =>{
    let purchaseArray= [];
    items.forEach(
      item=> {
        purchaseArray.push(
          {
            name: createNameString(item.label),
            id:item.id,
            price: ( item.price?.totalPrice || item.totalPrice ) +"",
            category:createCategoryString(item.label),
            quantity: item.quantity+"",
          }
        )
      }
    )
    return purchaseArray;
  }

  const createTotalRevenue = (items:any[]):number=>{
      if(items.length>1) {
        return items.reduce((a, b) => ( (a.price?.totalPrice || a.totalPrice) + (b.price?.totalPrice || b.totalPrice)))
    }

      let totalPrice = items[0].price?.totalPrice || items[0].totalPrice;
      let roundedRevenue = totalPrice.toFixed(2);

    return roundedRevenue;
  }

  const createTax= (totalRevenue:number):string=>{
    let totalTax = totalRevenue/6;
    let roundedTax  = totalTax.toFixed(2);
    return roundedTax;
    //dynamic version
    //   if(items.length >1) {
    //     const taxArray = [];
    //     items.forEach(item=>{
    //     taxArray.push(...item.price.calculatedTaxes)
    //   })
    //   return taxArray.reduce((a, b) => ( a.tax + b.tax))
    // }
    // return items[0].price.calculatedTaxes[0].tax;
  }

export const firePurchase = (items:any[],orderNumber:string,shipping:number):void=>{
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
    'ecommerce': {
    'purchase': {
    'actionField': {
    'id': orderNumber,
    'revenue': createTotalRevenue(items)+"",
    'tax':createTax(createTotalRevenue(items)),
    'shipping': shipping+"",
    },
    'products': createProductPurchaseArray(items)
    }
    }
    });
   }