import React, {useContext, useState} from "react";
import LanguageSwitcherRepository from "./LanguageSwitcherRepository";
import { useSelector, useDispatch } from 'react-redux';
import { StoreContext } from "../Store";
import { useHistory, useLocation } from "react-router-dom";

function LanguageSwitcherFooter() {
    const { setValues, contextState } = useContext(StoreContext)
    const selectedLanguageId = contextState.selectedLanguageId;
    const languages = contextState.languages;
    const history = useHistory()
    
    
    function getShortCode(longCode) {
        return longCode.substr(0,2);
    }

    function getItemDivider(language) {
        if ((languages.indexOf(language) + 1) !== languages.length) {
            return <React.Fragment>&nbsp;|&nbsp;</React.Fragment>
        }
    }

    function setSelectedLanguage(languageId, languageName) {
        // dispatch({type: 'setLanguageId', value: languageId});
        // dispatch({type: 'setLanguageName', value: languageName});
        // window.location.reload();
    }

    const renderedLanguages = [];
    const location = useLocation();
    const originPath = location.pathname;
    const modifiedPath = originPath.replace("/en", "");

    languages.forEach(language => {
        renderedLanguages.push(
            <React.Fragment key={language.id}>
                <a
                    className={'languageSwitch__link ' + (selectedLanguageId === language.id ? 'languageSwitch__link-active' : '')}
                    // onClick={()=>{history.push(language.name === "Deutsch" ? "/" :"/en/")}}
                    href={language.name === "Deutsch" ?  (window.location.origin + '' + modifiedPath) : ( window.location.origin + '/en' + modifiedPath)}
                    // onClick={() => { setSelectedLanguage(language.id, language.name); }}
                    lang={getShortCode(language.translationCode.code)}
                >
                    {getShortCode(language.translationCode.code)}
                </a>
                {getItemDivider(language)}
            </React.Fragment>
        );
    });

    return (
        <div className="languageSwitch" aria-label="Sprachwechsel">
            {renderedLanguages}
        </div>
    );
};

export default LanguageSwitcherFooter;