import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

function imageError() {
    // das ist ein platzhalter. falls nötig müssten wir hier error handling code reintun.
    // die original funktionen für onload und onerror können wir leider nicht übernehmen (nicht sichtbar)
}

function customRenderItem(item) {
    const itemSrc = item.original;

    return (
        <div>
            {
                item.imageSet ? (
                    <picture
                        onError={imageError}
                    >
                        {
                            item.imageSet.map((source, index) => (
                                <source
                                    key={`media-${index}`}
                                    media={source.media}
                                    srcSet={source.srcSet}
                                    type={source.type}
                                />
                            ))
                        }
                        <img
                            className="image-gallery-image"
                            alt={item.originalAlt}
                            src={itemSrc}
                            height={item.originalHeight}
                            width={item.originalWidth}
                        />
                    </picture>
                ) : (
                    <InnerImageZoom
                        className="image-gallery-image"
                        src={itemSrc}
                        zoomSrc={item.fullsize}
                        alt={item.originalAlt}
                        title={item.originalTitle}
                        onError={imageError}
                    />
                )
            }

            {
                item.description && (
                    <span className="image-gallery-description">
              {item.description}
            </span>
                )
            }
        </div>
    );
}

export default customRenderItem;