import "./Category.scss";
import React, { useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Card from "chamaeleon-react/components/Card/Card.tsx";
import CardHeadImage from "chamaeleon-react/components/Card/CardHeadImage";
import CardBody from "chamaeleon-react/components/Card/CardBody";
import CardTeaserContent from "chamaeleon-react/components/Card/CardTeaserContent";
import Currency from "../Helpers/Currency";
import { getGAHook } from "../GoogleAnalytics/GAServiceHook";
import { setInputFilter } from "../ProductDetail/ProductDetail.service";
import { StoreContext } from "../Store";
import { Button } from "@acl/design-base";

function ProductListItem({ product, translations, addToCart, position }) {
  const { contextState, setValues } = useContext(StoreContext);
  const selectedLanguageName = contextState.selectedLanguageName;

  const qty = useRef(null);

  let addToCardButton: any = "";
  let deactivateAddToCard: any = "";
  const { fireAddToCart, fireProductClick } = getGAHook();

  useEffect(() => {
    setInputFilter(qty.current, function (value) {
      return (
        /^\d*$/.test(value) &&
        (value === "" ||
          parseInt(value) <=
            product?.customFields?.customAvailableStock) &&
        (value === "" || parseInt(value) > 0)
      );
    });
  }, []);

  function qtyToMiniCartProduct(product) {
    let quantity;
    let priceTotal;

    quantity = qty.current.value;

    if (
      quantity.length > product.maxPurchase.length ||
      quantity > product.maxPurchase
    ) {
      if (product?.customFields?.customAvailableStock< product.maxPurchase
      ) {
        qty.current.value = product?.customFields?.customAvailableStock;
        quantity = qty.current.value;
      } else {
        qty.current.value = product.maxPurchase;
        quantity = qty.current.value;
      }
    }

    priceTotal = product.calculatedPrice.unitPrice * quantity;
    setValues({
      type: "set",
      field: "cartProduct",
      value: {
        id: product.id,
        quantity: quantity,
        label: product.name,
        price: product.calculatedPrice.unitPrice,
        priceTotal: priceTotal,
      },
    });
  }
  addToCardButton = (
    <div>
      <Link
        to={window.location.pathname}
        onClick={() => {
          addToCart(product.id, qty.current.value);
          fireAddToCart(product, qty.current.value);
        }}
        className="linkbtn linkbtn__primary"
      >
        <span className="linkbtn__text">
          {translations["detail.addProduct"]}
        </span>
        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
      </Link>
      <Link
        to={
          selectedLanguageName === "English"
            ? `/en/product/${product.id}/`
            : `/product/${product.id}/`
        }
        className="linkbtn linkbtn__secondary"
        onClick={() => fireProductClick(product, position)}
      >
        <span className="linkbtn__text">
          {translations["listing.boxProductDetails"]}
        </span>
        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
      </Link>
    </div>
  );

  deactivateAddToCard = (
    <div>
      <Button
        text={translations["detail.addProduct"]}
        classes={"linkbtn linkbtn__primary disabled"}
      />

      <Button
        href={ selectedLanguageName === "English"
            ? `/en/product/${product.id}/`
            : `/product/${product.id}/`}
        text={translations["listing.boxProductDetails"]}
        onClickFn={() => {
          fireProductClick(product, position);
        }}
        classes={"linkbtn linkbtn__secondary"}
      />
    </div>
  );

  function overlayImage(_product) {
    let overlayImageUrl = null;
    if (_product.availableStock <= 0) {
      overlayImageUrl = "/image_badge_soldout.png"; // soldOut image url
    } else if (_product?.customFields?.customAvailableStock <= 0) {
      overlayImageUrl = "/image_badge_soldout.png"; // reservedOut image url
    } else {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <img
        src={overlayImageUrl}
        alt="product sold out"
        id="productImageBadge"
      />
    );
  }

  return (
    <div className="col-lg-6 col-md-6 col-sm-6" key={product.id}>
      <Card type="teaser">
        {overlayImage(product)}
        <CardHeadImage
          headline={product.name}
          headline_white={true}
          src_lg={product.cover?.media?.url}
          src_md={product.cover?.media?.url}
          src_sm={product.cover?.media?.url}
          alt="example alt text"
          lazy={false}
        />
        <CardBody>
          <CardTeaserContent
            headline={product.name}
            text={Currency.format(product.calculatedPrice.totalPrice)}
          />
          <form className="cham-form product-details-form">
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor={"qty_" + product.id}>
                  {translations["detail.dataColumnQuantity"]}
                </label>
                <input
                  id={"qty_" + product.id}
                  name={"qty_" + product.id}
                  ref={qty}
                  type="number"
                  maxLength={2}
                  placeholder="z.B. 1"
                  defaultValue="1"
                  min="1"
                  max={product?.customFields?.customAvailableStock}
                  onChange={() => {
                    qtyToMiniCartProduct(product);
                  }}
                />
              </div>
            </div>
          </form>
          {product?.customFields?.customAvailableStock > 0
            ? addToCardButton
            : deactivateAddToCard}
          {/* <Link
                        to={`#`}
                        onClick={() => addToCart(product.id, qty.current.value)}
                        className="linkbtn linkbtn__primary">
                        <span className="linkbtn__text">{translations['custom.addtobasketbutton']}</span>
                        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </Link>
                    <Link
                        to={`/product/${product.id}/`}
                        className="linkbtn linkbtn__secondary">
                        <span className="linkbtn__text">{translations['listing.boxProductDetails']}</span>
                        <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </Link> */}
          {/* <div className="fwTeaser__content__richtext productdetails_onchain">
                            <ul className="checkmarks">
                                {translations['custom.onchainbuttonteaser']}
                            </ul>
                        </div>
                        <a href= {translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                    ? "#"
                                    : translations['custom.onchainbuttonteaserlink']}
                           className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                            <span className="linkbtn__text">{translations['custom.onchainbutton']}</span>
                            <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                        </a> */}
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductListItem;
