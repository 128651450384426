import HTMLReactParser from 'react-html-parser';

const FormBlock : any = (props : any) => {
        let renderedSlots : any[] = [];

        props.slots.forEach((slot : any) => {
            renderedSlots.push(HTMLReactParser(slot.data.content));
        });

        return (
            <div>{renderedSlots}</div>
        );
};

export default FormBlock;