import {createCategoryString, createNameString} from "./Shared.service";
import {Item} from "./InterfacesGA"
declare const window: any;

const getWindowLayerObject = (item:Item,price:number,type:("removeFromCart" | "addToCart"),quantity:number=1)=>{
    return {
    'event': type,
    'ecommerce': {
    'remove': {
    'products': [{
    'name': createNameString(item.label),
    'id': item.id,
    'price': price+"",
    'category': createCategoryString(item.label),
    'quantity': quantity+""
    }]
    }
    }
    }
}

export const fireRemoveOneFromCart = (item)=>{
    const price = item.price.unitPrice;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(
        getWindowLayerObject(item,price,'removeFromCart')
    );
}

export const fireRemoveAllFromCart = (item)=>{
    const price = item.price.totalPrice;
    const quantity= item.quantity;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(
        getWindowLayerObject(item,price,'removeFromCart',quantity)
    );
}

export const fireAddItemToCart = (item)=>{
    const price = item.price.unitPrice;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(
        getWindowLayerObject(item,price,'addToCart')
    );
}


