import { AppProps } from "./SalutationField";
import {Button} from "@acl/design-base";

interface LoginInfoProps {
    props:any,
    translations:string[],
    ClickHandler:()=>void
}

const LoginInfo= ({props,translations,ClickHandler}:LoginInfoProps)=> {

    if (props?.checkout?.customer?.id) {
        return <></>;
    }


  return (<>
            <div className={translations['custom.kiam']}> 
                <h2>{translations['custom.shippingDetail.postheader']}</h2>
                <div className="vesandinfo-btn-container">

                <Button
                    onClickFn={()=>{
                        ClickHandler();
                    }}
                    text={translations['custom.shippingDetail.posttext']}

                />
                </div>
                <hr className='versandinfo-separator'/>
            </div>
        </>);
}

export default LoginInfo;
