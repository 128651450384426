class Currency {
    public static format(number : number) {
        if (number === null || number === undefined) {
            return null;
        }
        const formatter = new Intl.NumberFormat('de-AT',
            { style: 'currency', useGrouping: true, currency: 'EUR',
                minimumFractionDigits: 2 });
        return formatter.format(number);
    }
}

export default Currency;