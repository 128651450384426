class AddressTransformer {
    public static appAddress(swAddress, swCustomer = null) {
        let endOfStreetAddress = (swAddress?.street?.lastIndexOf(' ') > 0 ? swAddress?.street?.lastIndexOf(' ') : swAddress?.street?.length);
        return {
            salutation: (swCustomer && swCustomer.salutation ? swCustomer.salutation.displayName : ''),
            salutationId: swAddress.salutationId || null,
            title: swAddress.title || swCustomer.title || '',
            firstname: swAddress.firstName || '',
            lastname: swAddress.lastName || '',
            street: swAddress.street.substring(0, endOfStreetAddress) || '',
            housenumber: swAddress.street.substring(endOfStreetAddress) || '',
            staircase: '',
            doornumber: '',
            areacode: swAddress.zipcode || '',
            country: swAddress.country.name || '',
            countryId: swAddress.countryId || null,
            city: swAddress.city || '',
            email: (swCustomer ? swCustomer.email : ''),
            phoneNumber: swAddress.phoneNumber || '',
            birthdayDay: (new Date(swCustomer.birthday).getDate()) || '',
            birthdayMonth: (new Date(swCustomer.birthday).getMonth() + 1) || '',
            birthdayYear: (new Date(swCustomer.birthday).getFullYear()) || ''
        };
    }
}

export default AddressTransformer;