import 'yup-phone'


import React, {useEffect, useState,useContext} from 'react'
import {useDispatch, useSelector} from "react-redux";
import CartRepository from "../../Cart/CartRepository";
import MiniCart from '../../Cart/MiniCart';
import {useHistory} from 'react-router-dom';
import PersonalDataForm from '../../Components/Shared/PersonalDataForm';
import {getGAHook} from "../../GoogleAnalytics/GAServiceHook";
import { StoreContext } from '../../Store';


function CheckoutAddresses(props) {
    const { contextState, setValues} = useContext(StoreContext)
    const history = useHistory()
    const [showContent, setShowContent] = useState(false);
    const translations = contextState.translations
    const workflowStatus = contextState.workflowStatus;
    const {selectedLanguageName} = contextState;

    const productsToCheckOut = contextState?.cart?.lineItems;
     const  {fireCheckout} = getGAHook();
     const shipping= 0;
     const timestamp = + new Date();
     const data= {
       items:productsToCheckOut,
       shipping:shipping,
       timestamp: timestamp
     }
     sessionStorage.setItem("ga-data", JSON.stringify(data))

     useEffect(() => {
        fireCheckout(productsToCheckOut);
     }, [])
     

    
    useEffect(() => {
        if (workflowStatus === null) {
            setShowContent(false);
        } else if (workflowStatus === CartRepository.cancelled) {
            history.push('/cart');
        } else if (workflowStatus === CartRepository.failed) {
            history.push('/');
        } else if (workflowStatus === CartRepository.inProgress) {
            setShowContent(true);
        }
    }, [workflowStatus]);

    let notification = <React.Fragment/>;
    if (history.location && history.location['state'] && history.location['state']['from'] && history.location['state']['from'] === 'cancel') {
        notification =
            <div className="notification headerspace">
                <div className="notification__icon__box">
                      <span
                          className={`notification__icon posticon_Posticon-Information`}
                          aria-hidden="true"></span>
                </div>
                <div className="notifications__container">
                    <div className="notification__text">
                        {translations['custom.payment.error']}
                    </div>
                </div>
            </div>
        ;
    }

    if (showContent === false) {
        return <div className="spinner">
            <div></div>
        </div>;
    }

    
    /* function nextDisabled() {
      return this.state.isStepComplete ? "" : "disabled";
    } */




    return (
        <div className="headerspace">
            <div className="container content__container">
                <div className="row content__row">
                    <div className="col-md-12 col-lg-8">
                        <div className="cham-form">
                            {notification}
                            <PersonalDataForm 
                            customer={props?.checkout?.customer}
                            address={props?.checkout?.address} mode={"register"}/>
                            
                                    </div>
                                    </div>

                                    <div className="sidebar col-md-12 col-lg-4">
                                    <div className="sidebar__container">
                                    <MiniCart/>
                                    <aside role="complementary" className="sidebar__main">
                                    <h2 className="heading--h2"></h2>
                                    <p></p>
                                    <div className="container">
                                    <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                {translations['custom.order-info-header']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                {translations['custom.order-info-text']}
                                {" "}
                                    </p><br />
                                    <p className="zahlung-und-versand-info__content">
                                {translations['custom.order-info-text-tax']}
                                    </p>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="container">
                                    <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                {translations['custom.order-info-header-delivery']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                {translations['custom.order-info-text-delivery']}
                                    </p>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="container">
                                    <div className="row">
                                    <div className="col-12 col-md-5 col-lg-12">
                                    <h3 className="heading heading--h4">
                                {translations['custom.order-info-header-payment']}
                                    </h3>
                                    <p className="zahlung-und-versand-info__content">
                                {translations['custom.order-info-text-payment']}
                                    </p>
                                    <div className="zahlung-und-versand-info__images">
                                    <div className="zahlung-und-versand-info__image">
                                    <img src="/payment-logos/Klarna.svg"></img>
                                    </div>
                                    <br/>
                                    <div className="zahlung-und-versand-info__image">
                                    <img src="/payment-logos/mc.svg"></img>
                                    </div>
                                    <div className="zahlung-und-versand-info__image">
                                    <img src="/payment-logos/visa.svg"></img>
                                    </div>
                                    <br/>
                                {/* d */}
                                    </div>
                                    </div>
                                    <div
                                    className="col-12 offset-md-1 col-md-5 col-lg-12 offset-lg-0 panel-right"></div>
                                    </div>
                                    </div>
                                    </aside>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    )
                                }

export default CheckoutAddresses
