import {createImpressionsArray,createNameString,createCategoryString} from "./Shared.service"
import {Product} from "./InterfacesGA";

declare const window: any;

const createProductArray = (product:Product):any[] =>{
    return [{
    'name': createNameString(product.name),
    'id': product.id,
    'price': product.calculatedPrice.unitPrice+"",
    'category': createCategoryString(product.name),
    }]
}

  export const fireCombinedProductDetailViewProductImpr = (product:Product,crossSellingProducts:any[]):void =>{
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
     'event': 'product_detail',
     'ecommerce': {
     'impressions': createImpressionsArray(crossSellingProducts)
     ,
     'detail': {
     'products': createProductArray(product)
     }
     }
    });
    }

    export const fireProductDetailView = (product:Product):void =>{

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
        'event': 'product_detail',
        'ecommerce': {
        'currencyCode': 'EUR', // Local currency is optional.
        'detail': {
        'products':createProductArray(product)
        }
        }
        });
        }

