import { useField } from "formik";
// import "./NotificationForm.scss";
import "./../../Checkout/Checkout.scss";

const Custominput = ({ label, name, ...props }) => {
  const [field, meta] = useField({ name, ...props });

  return (
    <>
      <label className="col-12 customLabel">{label}</label>
      <input
        {...field}
        {...props}
        className={`customInput ${field.name === "quantity" ? "customLabel-quantity" : "col-12"} ${
          meta.touched && meta.error ? "input-error" : ""
        }`}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default Custominput;
