import React, { useState, useRef, useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./NotificationForm.scss";
import ShopwareAPI from "../../ShopwareAPI/ShopwareAPI";
import { createNotification } from "../../NotificationAPI/NotificationAPI";
import { toast } from "react-toastify";
import { StoreContext } from "../../Store";
import { Button, Modal, ModalConfirmation } from "@acl/design-base";
import { SubmitButton } from "@acl/design-form";
import Custominput from "./CustomInput";

interface Props {
  productName: string;
  productId: string;
  translations: [];
}

function NotificationForm({ productName, productId, translations }: Props) {
  const { contextState, setValues } = useContext(StoreContext);
  const customer = contextState.context.customer;
  const ssoid = customer?.customFields?.custom_postcustomer_ssoid;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const header = `${translations["detail.notification.headerOne"]} ${productName} ${translations["detail.notification.headerTwo"]}`;

  const disallowUmlaut = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .max(45, translations['detail.notification.validationNameMaxLength'])
      .required(translations['detail.notification.validationRequired']),
      surname: yup
      .string()
      .max(45, translations['detail.notification.validationNameMaxLength'])
      .required(translations['detail.notification.validationRequired']),
    email: yup
      .string()
      .matches(disallowUmlaut, translations['detail.notification.validationEmail'])
      .required(translations['detail.notification.validationRequired']),
    quantity: yup
      .number()
      .min(1, translations['detail.notification.validationQuantityMin'])
      .max(100, translations['detail.notification.validationQuantityMax'])
      .required(translations['detail.notification.validationRequired']),
  });

  const onSubmit = async (values, actions) => {
    values.productId = productId;
    try {
      const response = await createNotification(values);
      const message = translations["detail.notification.alertMessage"];
      const alertLink = message.includes("privacy policy")
        ? "https://www.post.at/en/i/c/data-protection"
        : "https://www.post.at/i/c/datenschutz";

      const alertMessage = (
        <>
          <div className="custom-notification-container">
            <div className="custom-notification-primary">
              <div className="notification__icon__box custom-notification-icon-box">
                <span
                  className={`notification__icon posticon_Posticon-Information`}
                  aria-hidden="false"
                ></span>
              </div>
              <div className="custom-notification-alert-message">
                {translations["detail.notification.alertMessage"]}
                <a style={{ color: "unset" }} href={alertLink}>
                  {translations["detail.notification.alertLink"]}.
                </a>
              </div>
            </div>
          </div>
        </>
      );

      if (response) {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";

        toast.success(alertMessage, {
          position: "top-center",
          autoClose: 30000,
          className: "custom-toast-alert",
          hideProgressBar: true,
          closeButton: false,
        });
        actions.resetForm();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <React.Fragment>
      {ssoid ? (
        <button
          type="button"
          className="linkbtn linkbtn__primary"
          data-toggle="modal"
          data-target="#exampleModal"
          data-backdrop="false"
          onClick={() => setIsModalOpen(true)}
        >
          <span className="linkbtn__text">
            {translations["detail.notification"]}
          </span>
          <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
        </button>
      ) : (
        <button
          type="button"
          className="linkbtn linkbtn__primary disabled"
          data-toggle=""
          data-target="#exampleModal"
        >
          <span className="linkbtn__text custom-linkbtn-disable">
            <b>{translations["detail.notification"]}</b> <br />
            {translations["detail.notification.disableText"]}
          </span>
        </button>
      )}

      <Modal
        name={`warteliste-modal`}
        closeModalFn={() => {
          setIsModalOpen(false);
        }}
        show={isModalOpen}
      >
        <ModalConfirmation
          icon={"posticon_Posticon-Information"}
          headline={header}
        >
          <Formik
            initialValues={{
              firstname: customer?.firstName,
              surname: customer?.lastName,
              email: customer?.email,
              productName: productName,
              quantity: 1,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form noValidate className="cham-form">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <Custominput
                        label={
                          translations["detail.notificiationForm.firstName"]
                        }
                        name="firstname"
                        type="text"
                        placeholder="Maxi"
                      />
                      <Custominput
                        label={translations["detail.notificiationForm.surname"]}
                        name="surname"
                        type="text"
                        placeholder="Mustermann"
                      />
                      <Custominput
                        label={translations["detail.notificiationForm.email"]}
                        name="email"
                        type="email"
                        placeholder="maxi@muster.at"
                      />
                      <Custominput
                        label={translations["detail.dataColumnQuantity"]}
                        name="quantity"
                        type="number"
                        maxLength={2}
                        placeholder="z.B. 1"
                        value={values.quantity}
                        min="1"
                        max="100"
                        onChange={handleChange}
                      />
                      <SubmitButton
                        classes="linkbtn linkbtn__primary"
                        icon={"posticon_Posticon-Button-Pfeil-Weiter"}
                        disabled={isSubmitting}
                        text={translations["detail.notification"]}
                      />
                      <Button
                        onClickFn={(e) => {
                          setIsModalOpen(false);
                        }}
                        variant="secondary"
                        icon={"posticon_Posticon-Button-Pfeil-Zurueck"}
                        text={translations["detail.notification.cancel"]}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalConfirmation>
      </Modal>
    </React.Fragment>
  );
}
export default NotificationForm;
