import "../Checkout.scss";


import {useState, useContext } from "react";
import { StoreContext } from "../../Store";
import { Button } from "@acl/design-base";
import PersonalDataForm from "../../Components/Shared/PersonalDataForm";

import { Modal } from "@acl/design-base";
import { ModalContentBody } from "@acl/design-base";
import { ModalContentHeader } from "@acl/design-base";


/**
 * InvoiceAddress Edit properties.
 */
export interface IInvoiceAddressEdit {
  customer:{};
  title: string;
  btntitle: string;
  addressParam: string;
  address?: {};
  shippingAddress?: {};
}

function AddressEditModal (props:IInvoiceAddressEdit) {
  const {contextState} = useContext(StoreContext);

  const [showModalContent, setShowModalContent] = useState(false);
  const translations = contextState.translations
  
  /*   const {
    checkoutViewValues: {
      cart: { invoiceAddress },
    },
  } = useContext(KassaContext); */
  // const dictionary = useContext(TranslationContext);

  const hideModal = () => {
    setShowModalContent(false);
  };

  return (
    <div
      className={[
        "invoiceaddress__edit",
        /* !invoiceAddress && */ "scrolltarget",
      ].join(" ")}>
      <div className="invoiceaddress__edit-button">
        <Button
          onClickFn={() => {
            setShowModalContent(true);
          }}
          variant="secondary"
          icon="posticon_Posticon-Button-Pfeil-Weiter"
          text= {props.btntitle}
        />
      </div>
        <Modal
          name="ModalContent"
          show={showModalContent}
          closeModalFn={() => setShowModalContent(false)}>
          <ModalContentHeader
            closeMobileLabel= {translations['account.editOrderCancelOrderModalFooterButtonBack']}
            headingLabel={props.title}
          />
          <ModalContentBody closeDesktopLabel= {translations['custom.addressmodalclose.text']}>
          <PersonalDataForm
            customer={props.customer} 
            address={props.address}
            mode={"edit"}
            closeModalFunc={hideModal}
            addressParam={props.addressParam}
            />
          </ModalContentBody>
        </Modal>
    </div>
  );
};

export default AddressEditModal;
