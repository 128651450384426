import React from "react";
import { toast } from 'react-toastify';

class Notifier extends React.Component {
    private static getNotifierOptions() {
        return {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };
    }

    public static success(message: any) {
        toast.success(message, this.getNotifierOptions()); 
    }

    public static error(message: string) {
        toast.error(<div>
            <div className="col-12 modal">
              <div id="up-triangleError"></div>
              <div className="ReactModalConfirmation" id="test">
                <div className="ReactModalConfirmation__container">
                  <div className="ReactModalConfirmation__header ErrorModalHeader">
                    <span
                      aria-hidden="true"
                      className={`ReactModalConfirmation__icon`}></span>
                    <h4 className="ReactModalConfirmation__headline">
                    Fehler
                    </h4>
                  </div>
                  <div className="ReactModalConfirmation__body ErrorModalBody">
                   {message}
                  </div>
                </div>
              </div>
            </div>
          </div>, this.getNotifierOptions());
    }

    public static info(message: any) {
        toast.info(message, this.getNotifierOptions());
    }

}export default Notifier;
