import {fireProductImpression} from "./CategoryGA.service"
import {fireCheckout} from "./CheckoutGA.service"
import {fireCombinedProductDetailViewProductImpr,fireProductDetailView } from "./ProductDetailGA.service"
import { fireAddToCart,fireProductClick } from "./Shared.service"
import {fireRemoveOneFromCart,fireRemoveAllFromCart,fireAddItemToCart} from "./CartGA.service"
import { firePurchase } from "./CheckoutSuccessPageGA.service"


const gaHooks = {
    fireProductImpression,
    fireCheckout,
    fireCombinedProductDetailViewProductImpr,
    fireProductDetailView,
    fireAddToCart,
    fireProductClick,
    fireRemoveOneFromCart,
    fireRemoveAllFromCart,
    fireAddItemToCart,
    firePurchase
}

export const getGAHook = () =>{
    return gaHooks;
}