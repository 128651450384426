import "./Home.scss";
import * as React from "react";
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import TextBlock from "../CmsBlocks/TextBlock";
import ImageTextBlock from "../CmsBlocks/ImageTextBlock";
import ImageBlock from "../CmsBlocks/ImageBlock";
import ProductImageOverlayBlock from "../CmsBlocks/ProductImageOverlayBlock";
import BannerBlock from "../CmsBlocks/BannerBlock";
import ProductSlider from "../CmsBlocks/ProductSlider";
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../Store";



function Home(props:any) {
 const { contextState, setValues} = useContext(StoreContext)
 const [content, setContent] = useState<{[data:string]:any}>()
 const history = useHistory()
 const {selectedLanguageName} = contextState;
 



 useEffect(() => {
   if(!content) {
    setTimeout(() => {
        let api = new ShopwareAPI();
        api
            .fetch("/category/home")
            .then((response) => {
                setContent({data: response});
            })
            .catch((error) => console.error(`API call error: ${error}`))    
    }, 80);    
   }
 }, [selectedLanguageName])

 

 let renderedCmsPage: any = [];
 if(content){
            content.data.cmsPage.sections.forEach((section: any) => {
            let sectionParts: any = [];
            section.blocks.forEach((block: any) => {
                if (block.type === "text") {
                    sectionParts.push(<TextBlock slots={block.slots} block={block} key={uuidv4()}/>);
                } else if (block.type === "product-image-overlay") {
                    sectionParts.push(<ProductImageOverlayBlock slots={block.slots} key={uuidv4()}/>);
                } else if (block.type === "image-cover" || block.type === "image-two-column") {
                    sectionParts.push(<ImageBlock slots={block.slots} key={uuidv4()}/>);
                } else if (block.type === "banner") {
                    sectionParts.push(<BannerBlock slots={block.slots} key={uuidv4()}/>);
                } else if (block.type === "image-text") {
                    sectionParts.push(<ImageTextBlock slots={block.slots} key={uuidv4()}/>);
                } else if (block.type === "product-slider") {
                    sectionParts.push(
                        <ProductSlider
                            slots={block.slots}
                            setDisplayHeaderModal={this.props.setDisplayHeaderModal}
                            key={uuidv4()}
                        />
                    );
                }
            });

            renderedCmsPage.push(
                <div className="section" key={uuidv4()}>
                    {sectionParts}
                </div>
            );
        });
    }

    else {

        renderedCmsPage = (<>
        
        <div
            className="placeHolderHome"
        >
        </div>
        
        </>)
    }
 
  return (
    <div 
        className={"wrapper"}>   
            {renderedCmsPage}
    </div>
  )
}

export default Home




