import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";

export const createNotification = async (values) => {
  try {
    const { productName, productId, quantity, ...otherValues } = values;

    const api = new ShopwareAPI();
    const response = await api.write("/stock-notification", {
      productName,
      productId,
      quantity,
      ...otherValues,
    });

    if (response) {
      return response;
    }
  } catch (error) {
    console.error("Error creating notification:", error);
    throw error;
  }
};
