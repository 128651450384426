import "./ProductImageOverlayBlock.scss";
import Hero_Main from "chamaeleon-react/components/Hero/Hero_Main.tsx";
import Hero_Main_Right from "chamaeleon-react/components/Hero/Hero_Main_Right.tsx";
import HTMLReactParser from "react-html-parser";
import {useEffect, useState, useContext} from 'react';
import Headline from "chamaeleon-react/components/Headline/Headline";
import {useSelector} from "react-redux";
import CategoryRepository from "../Category/CategoryRepository";
import LinkButtonGroup from "./LinkButtonGroup";
import { StoreContext } from "../Store";
import { useHistory } from "react-router-dom";
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import CartRepository from "../Cart/CartRepository";


const ProductImageOverlayBlock: any = (props: any) => {
    const { contextState, setValues} = useContext(StoreContext)
    const history = useHistory();
    
    const translations =  contextState.translations;
    const [products, setProducts] = useState([]);
          

      
    let renderedSlots: any[] = [];
    let product: any = null;
    let images: any[] = [];
    let headline = '';
    let soldOutText = null;
    let category: any = null;

    useEffect(() => {
        if (!category) {
            return;
        }
        CategoryRepository.products(category.id).then(products => {
            setProducts(products);
        });
    }, [category]);

    props.slots.forEach((slot: any) => {
        if (slot.type === 'product-box') {
            product = {
                id: slot.data.product.id,
                name: slot.data.product.name,
                description: HTMLReactParser(slot.data.product.description),
                price: slot.data.product.calculatedPrice.unitPrice,
                image: slot.data.product.cover.media.url,
                availableStock: slot.data.product.availableStock,
            };
        } else if (slot.type === 'category') {
            category = slot.data?.category;
        } else if (slot.type === 'image') {
            images.push({
                id: slot.data.media.id,
                url: slot.data.media.url
            });
            //HIER WIRD DAS FALSCHE HEADING IN HOME AUSGEGEBEN
        } else if (slot.type === 'plaintext') {
            headline = slot.config.content.value ?? '';
        } else if (slot.type === 'text') {
            soldOutText = slot.config.content.value ?? '';
        }
    });

    let buttonLink = translations['custom.eurobuttonlink'];
    let buttonDisabledClass = '';

    if (product != null) {
        buttonLink = `/product/${product.id}/`;

        if (product.availableStock <= 0) {
            buttonDisabledClass = 'disabled';
            buttonLink = "#";

            images[1] = images[2];
        }
    } else if (category != null) {
        buttonLink = `/home/${category.name}/`;

        let soldOutProducts = products.filter(e => e.availableStock <= 0).length;
        if (soldOutProducts === products.length) {
            buttonDisabledClass = 'disabled';
            buttonLink = "#";

            images[1] = images[2];
        }
    }

    renderedSlots.push(
        (
            <div className={"header-image-right"} key={images[0].id}>
                <Hero_Main_Right
                    >
                    <Headline
                        type="h1"
                        classes="heading heading--fineline-inline heading-home">
                        {headline}
                    </Headline>
                    <img src={images[0].url}/>
                    <LinkButtonGroup
                        translations={translations}
                        buttonLink={buttonLink}
                        buttonTitle={translations['custom.eurobutton']}
                        buttonDisabledClass={buttonDisabledClass}
                        soldOutText={soldOutText}
                    />
                </Hero_Main_Right>
            </div>
        ));

    renderedSlots.push(
         <div className={"header-image"} key={images[1].id}>
            <Hero_Main imgSM={images[1].url}
                       imgMD={images[1].url}
                       imgLG={images[1].url}
                       imgAlt="Cryptostamp Header">
            </Hero_Main> 
         </div> 
    );

    return (
        <div className="headerspace">
            {renderedSlots}
        </div>
    );
};

export default ProductImageOverlayBlock;