import "./ProductDetail.scss";
import React, {useEffect, useState, useRef, useContext} from "react";
import ShopwareAPI from "../ShopwareAPI/ShopwareAPI";
import {Link, useHistory} from "react-router-dom";
import HTMLReactParser from "react-html-parser";
import {useSelector, useDispatch} from "react-redux";
import TeaserList from "chamaeleon-react/components/TeaserFullWidth/TeaserList";
import Headline from "chamaeleon-react/components/Headline/Headline";
import CartRepository from "../Cart/CartRepository";
import Currency from "../Helpers/Currency";
import MiniCartProduct from "../Cart/MiniCartProduct";
import MiniCart from "../Cart/MiniCart";
import CategoryRepository from "../Category/CategoryRepository";
import CardHeadImage from "chamaeleon-react/components/Card/CardHeadImage";
import CardBody from "chamaeleon-react/components/Card/CardBody";
import CardTeaserContent from "chamaeleon-react/components/Card/CardTeaserContent";
import Card from "chamaeleon-react/components/Card/Card.tsx";
import Modal from "bootstrap";
import ImageGallery from 'react-image-gallery';
import customRenderItem from './CustomRenderItem';
import {
        getFilteredCrossSellingProducts,
        setInputFilter
    } from "./ProductDetail.service";
import {getGAHook} from "../GoogleAnalytics/GAServiceHook"
import { StoreContext } from "../Store";
import { Button } from "@acl/design-base";
import { fireCheckout } from "../GoogleAnalytics/CheckoutGA.service";
import ReactModal from "react-modal";
import NotificationForm from "../Components/Shared/NotificationForm";



function ProductDetail(props) {
    const { setValues, contextState } = useContext(StoreContext)
    const translations = contextState.translations;
    const productId = props.match.params.productId;
    const [showContent, setShowContent] = useState(false);
    const selectedLanguageName = contextState.selectedLanguageName;
    const [counter, setCount] = useState(0);
    const cart = contextState.cart;
    const { fireCombinedProductDetailViewProductImpr,fireProductDetailView, fireAddToCart, fireProductClick } = getGAHook(); 
    const history = useHistory()

 
    const [product, setProduct] = useState(
         null
    );

    const fetchProduct = ()=>{
        let api = new ShopwareAPI();
        api
            .fetch("/custom-product/" + productId)
            .then((response) => {
                setProduct(response.product);
                setShowContent(true);
                console.log(response.product.seoCategory.id);
                CategoryRepository.products(response.product.seoCategory.id).then((categoryProducts) => {
                    setCrossSellingProducts(categoryProducts);
                });
            })
    }

    const [crossSellingProducts, setCrossSellingProducts] = useState(null);


    const qty = useRef(null);

      useEffect(() => {
          if(qty.current){
            setInputFilter(qty.current, function(value) {
            return /^\d*$/.test(value) && (value === "" || parseInt(value) <= product?.customFields?.customAvailableStock) && (value === "" || parseInt(value) > 0);
            })
        }
      }, [crossSellingProducts])
      
    useEffect(() => {
        fetchProduct()
    }, [productId]);


    useEffect(() => {
        if(crossSellingProducts) {
           const filteredCrossSellingProducts = getFilteredCrossSellingProducts(crossSellingProducts,product);
           fireCombinedProductDetailViewProductImpr(product,filteredCrossSellingProducts);
        }
        else if(!crossSellingProducts && product !== null) {
                fireProductDetailView(product);
            }
    }, [crossSellingProducts])


    function addToCart(productId: string, quantity: number = 1) {
        setValues({type: "set", field: "workflowStatus", value: null});
        setValues({type: "set", field: "countdownComplete", value: null});

        if (!cart || counter < 1) {
            setValues({type: "set", field: "showAlert", value: true});
        } else if (cart["lineItems"].length === 0) {
            setValues({type: "set", field: "showAlert", value: false});
        }

        CartRepository.add(productId, quantity).then((cart: any) => {
            setValues({type: "set", field: "cart", value: cart});
            setValues({type: "set", field: "workflowStatus", value: CartRepository.inProgress});
        });

        setCount(counter + 1);
    }

    if (showContent === false) {
        return <div className="spinner">
            <div></div>
        </div>;
    }
    if (!product) {
        return <div></div>;
    }

    function qtyToMiniCartProduct(product) {
        let quantity;
        let priceTotal;

        if (qty.current == null) {
            quantity = 1;
        }
        else {
            quantity = qty.current.value;
        }

        if(qty.current?.value) {
        if (quantity.length > product.maxPurchase.length || quantity > product.maxPurchase) {
            if (product.customFields?.customAvailableStock < product.maxPurchase) {
                qty.current.value = product?.customFields?.customAvailableStock;
                quantity = qty.current.value;
            } else {
                qty.current.value = product.maxPurchase;
                quantity = qty.current.value;
            }
        }

        priceTotal = product.calculatedPrice.unitPrice * quantity;
        setValues({
            type: "set", field: "cartProduct", value: {
                "id": product.id,
                "quantity": quantity,
                "label": product.name,
                "price": product.calculatedPrice.unitPrice,
                "priceTotal": priceTotal
            }
        });

    }
    }


    let addToCardButton: any = "";
    let deactivateAddToCard: any = "";

    addToCardButton = (
        <div>
            <form className="cham-form product-details-form">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="qty">{translations['detail.dataColumnQuantity']}</label>
                        <input
                            id="qty"
                            name="qty"
                            ref={qty}
                            type="number"
                            maxLength={2}
                            placeholder="z.B. 1"
                            defaultValue="1"
                            min="1"
                            max={product?.customFields?.customAvailableStock}
                            onChange={() => {
                                qtyToMiniCartProduct(product)
                            }}
                        />
                    </div>
                </div>
            </form>
             <Link 
                to={(window.location.pathname)}
                onClick={()=>{
                    addToCart(product.id, qty.current.value);
                    fireAddToCart(product,qty.current.value);
                }}
                className="linkbtn linkbtn__primary ">
                <span className="linkbtn__text">{translations['detail.addProduct']}</span>
                <span className="linkbtn__icon posticon_Posticon-Post-Onlineshop"></span>
            </Link>
            <Link
                onClick={()=>{
                    addToCart(product.id, qty.current.value);
                    //fireCheckout(cart.lineItems);
                  }}
                //to={(selectedLanguageName === "English" ? "/en/cart"  : "/cart" )}
                to={(selectedLanguageName === "English" ? "/en/checkout/shipment-infos"  : "/checkout/shipment-infos" )}
                className="productdetailsbutton linkbtn linkbtn__secondary">
                <span className="linkbtn__text">{translations['checkout.proceedLink']}</span>
                <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
            </Link> 
        </div>
    );

    deactivateAddToCard = (
        <div>
            <form className="cham-form product-details-form">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="qty">{translations['detail.dataColumnQuantity']}</label>
                        <input disabled
                               id="qty"
                               name="qty"
                               ref={qty}
                               type="number"
                               maxLength={2}
                               placeholder="z.B. 1"
                               defaultValue="1"
                               min="1"
                               max={product.maxPurchase}
                        />
                    </div>
                </div>
            </form>
            <Button
                classes={"linkbtn linkbtn__primary disabled"}
                text={translations['checkout.proceedLink']}
            />
            <Button
                classes={"productdetailsbutton linkbtn linkbtn__secondary disabled"}
                text={translations['detail.addProduct']}
                icon={"linkbtn__icon posticon_Posticon-Post-Onlineshop"}
            />
            <NotificationForm
                productName={product.name}
                productId={product.id}
                translations={translations}
            />
        </div>
    );


    let crossSelling = <React.Fragment></React.Fragment>
    if (crossSellingProducts && crossSellingProducts.length) {
        let renderedCrossSellingProducts = [];
        let filteredCrossSellingProducts = [];
        crossSellingProducts.forEach((_prod,i) => {
            if (_prod.id === product.id) {
                return;
            }
            filteredCrossSellingProducts.push(_prod)
            renderedCrossSellingProducts.push(
                <div className="col-lg-6 col-md-6 col-sm-12" key={_prod.id}>
                    <Card type="teaser">
                        {overlayImage(_prod)}
                        <CardHeadImage
                            headline={_prod.name}
                            headline_white={true}
                            src_lg={_prod.cover?.media?.url}
                            src_md={_prod.cover?.media?.url}
                            src_sm={_prod.cover?.media?.url}
                            alt="example alt text"
                            lazy={false}
                        />
                        <CardBody>
                            <CardTeaserContent
                                headline={_prod.name}
                                text={Currency.format(_prod.calculatedPrice.totalPrice)}
                            />
                            {/* <Link
                                to={`/product/${_prod.id}/`}
                                className="linkbtn linkbtn__primary"
                                onClick={()=>{fireProductClick(_prod,i+1)}}
                                >
                                <span className="linkbtn__text">{translations['listing.boxProductDetails']}</span>
                                <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                            </Link> */}
                            <Button
                                href={`/product/${_prod.id}/`}
                                onClickFn={()=>{fireProductClick(_prod,i+1)}}
                                text={translations['listing.boxProductDetails']}
                            />
                        </CardBody>
                    </Card>
                </div>
            );         
        });


            crossSelling =
                <div>
                    <h3 className="heading--h3">
                        <span>{translations['listing.otherproducts']}</span>
                    </h3>
                    <div className="row">
                        {renderedCrossSellingProducts}
                    </div>
                </div>;
    }


    let images = [];

    images.push({
        original: product.cover.media.thumbnails[1]?.url || product.cover.media.url,
        thumbnail: product.cover.media.thumbnails[0]?.url || product.cover.media.url,
        fullsize: product.cover.media.url
    });
    product.media?.forEach(media => {
        const languageCompare = selectedLanguageName || 'Deutsch';
        const imageLang = media.media.customFields?.custom_lang_lang || media.media.translated?.customFields?.custom_lang_lang;
        if (imageLang &&
            imageLang !== '' &&
            imageLang !== languageCompare
        ) {
            return;
        }
        if (product.cover.mediaId !== media.mediaId) {
            images.push({
                original: media.media.thumbnails[1]?.url || media.media.url,
                thumbnail: media.media.thumbnails[0]?.url || media.media.url,
                fullsize: media.media.url
            });
        }
    });

    let productTeaser = (
        <div className="fwTeaser">
            <div className="container">
                <div className="row row-no-padding">
                    <div className="col-12 col-md-6 col-lg-6 image-gallery-container">
                        {overlayImage(product)}
                        <div className="fwTeaser__image">
                            <ImageGallery items={images} showFullscreenButton={false} renderItem={customRenderItem}
                                          showPlayButton={false}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="fwTeaser__content">
                            <Headline
                                type="h2"
                                classes="heading heading--h2">
                                {product.name}
                            </Headline>
                            <div>{translations['detail.productNumberLabel']} {product.productNumber}</div>
                            <TeaserList>
                                <h3>
                                    {" "}
                                    {Currency.format(product.calculatedPrice.unitPrice)}{" "}
                                </h3>
                            </TeaserList>
                            <div>
                                {product?.customFields?.customAvailableStock > 0
                                    ? addToCardButton
                                    : deactivateAddToCard}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function nextDisabled() {
        if (
            product?.customFields?.customAvailableStock <= 0 ||
            product.availableStock <= 0
        ) {
            return "";
        }
        return "disabled";
    }

    function notificationText() {
        if (product.availableStock <= 0) {
            return product.customFields.custom_cryptonotification_soldout;
        } else if (product?.customFields?.customAvailableStock <= 0) {
            return product.customFields.custom_cryptonotification_reserved;
        } else {
            return "";
        }
    }

    function overlayImage(_product) {
        let overlayImageUrl = null;
        if (_product.availableStock <= 0) {
            overlayImageUrl = '/image_badge_soldout.png'; // soldOut image url
        } else if (_product?.customFields?.customAvailableStock <= 0) {
            overlayImageUrl = '/image_badge_soldout.png'; // reservedOut image url
        } else {
            return <React.Fragment></React.Fragment>;
        }

        return <img src={overlayImageUrl} alt="product sold out" id="productImageBadge"/>;
    }

    return (
        <div className="headerspace">
            <div className="container content__container">
                <div className="row content__row">
                    <div className="col-md-12 col-lg-8">
                        <div className="centerstage">
                            <main role="main" className="main">
                                <div className={"notification " + nextDisabled()}>
                                    <div className="notification__icon__box">
                                       
                    <span
                        className={`notification__icon posticon_Posticon-Information`}
                        aria-hidden="true"></span>
                                    </div>
                                    <div className="notifications__container">
                                        <div className="notification__text">
                                            {notificationText()}
                                        </div>
                                    </div>
                                </div>
                                {productTeaser}
                                <div>
                                    <h3 className="heading--h3">
                                        <span>{translations['detail.tabsDescription']}</span>
                                    </h3>
                                    <div>
                                        {HTMLReactParser(product.description)} <br/>
                                    </div>
                                </div>
                                {crossSelling}
                            </main>
                        </div>
                    </div>
                    <div className="sidebar col-md-12 col-lg-4">
                        <div className="sidebar__container">
                            {/* <MiniCartProduct /> */}
                            <MiniCart/>
                            <aside className="sidebar__main">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-12">
                                            <h3 className="heading heading--h4">
                                                {translations['custom.order-info-header']}
                                            </h3>
                                            <p className="zahlung-und-versand-info__content">
                                                {translations['custom.order-info-text']}
                                                {" "}
                                            </p><br/>
                                            <p className="zahlung-und-versand-info__content">
                                                {translations['custom.order-info-text-tax']}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-12">
                                            <h3 className="heading heading--h4">
                                                {translations['custom.order-info-header-delivery']}
                                            </h3>
                                            <p className="zahlung-und-versand-info__content">
                                                {translations['custom.order-info-text-delivery']}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="container"></div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-12">
                                            <h3 className="heading heading--h4">
                                                {translations['custom.order-info-header-onchain']}
                                            </h3>
                                            <p className="zahlung-und-versand-info__content">
                                                {translations['custom.order-info-text-onchain']}
                                            </p>
                                            {/* <a
                                                href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled'
                                                    ? "#"
                                                    : translations['custom.onchainlink']}
                                                className={"linkbtn linkbtn__secondary " + translations['custom.onchainbuttonteaserdisabled']}>
                                                <span
                                                    className="linkbtn__text">{translations['custom.onchainlinkbutton']}</span>
                                                <span
                                                    className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                                            </a> */}

                                            <Button
                                                href={translations['custom.onchainbuttonteaserdisabled'] == 'disabled' ? "#" : translations['custom.onchainlink']}
                                                classes={translations['custom.onchainbuttonteaserdisabled']}                                           
                                                text={translations['custom.onchainlinkbutton']}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-12">
                                            <h3 className="heading heading--h4">
                                                {translations['custom.order-info-header-payment']}
                                            </h3>
                                            <p className="zahlung-und-versand-info__content">
                                                {translations['custom.order-info-text-payment']}
                                            </p>
                                            <div className="zahlung-und-versand-info__images">
                                                <div className="zahlung-und-versand-info__image">
                                                    <img src="/payment-logos/Klarna.svg" alt="klarna"></img>
                                                </div>
                                                <br/>
                                                <div className="zahlung-und-versand-info__image">
                                                    <img src="/payment-logos/mc.svg" alt="mc"></img>
                                                </div>
                                                <div className="zahlung-und-versand-info__image">
                                                    <img src="/payment-logos/visa.svg" alt="visa"></img>
                                                </div>
                                                <br/>
                                                {/* <div className="zahlung-und-versand-info__image">
                          <img src="/payment-logos/DCI.svg" alt="dci"></img>
                        </div> */}
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 offset-md-1 col-md-5 col-lg-12 offset-lg-0 panel-right"></div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
