import React, { createContext, useEffect, useReducer, useState} from 'react';
// import { todoReducer } from './TodoReducer';
import { StoreReducer } from './StoreReducer';

const initialState = {
    context: null,
    checkout: {}, 
    selectedLanguageId: window.localStorage.getItem('sw_language_id'),
    selectedLanguageName: window.localStorage.getItem('sw_language_name'),
    cart: null,
    languages: {},
    countries: {},
    salutations: {},
    translations: {},
    navigation: [],
    cartExpiration: window.localStorage.getItem('cartExpiration'),
    workflowStatus: null,
    isFormSubmitted: false,
    showAlert: false,
    error: null,
    countdownAlert: null,
    countdownComplete: null,
    newsletter: false
};


export const StoreContext = createContext(initialState);


export function StoreContextProvider({ children }) {
    
    const [state, dispatch] = useReducer(StoreReducer, initialState);

    function setValues(valueObject) {
        dispatch({
            type:valueObject.type,
            value:valueObject.value,
            field: valueObject.field 
        })   
    }

    // function setValues(type,value,field=null) {
    //     dispatch({
    //         type,
    //         value,
    //         field
    //     })   
    // }

    function getValues(type,value) {
        dispatch({
            type,
            value
        })   
    }


    return (
      <StoreContext.Provider
        value={{
            contextState:state,
            setValues,
            getValues
        //   todos: state.todos,
        //   fetchTodos,
        //   saveTodo,
        }}
      >
      {children}
      </StoreContext.Provider>
    );
  }