import {Product} from "../GoogleAnalytics/InterfacesGA"
      
      export const setInputFilter = (textbox, inputFilter) => {
            ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
              textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                  this.oldValue = this.value;
                  this.oldSelectionStart = this.selectionStart;
                  this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                  this.value = this.oldValue;
                } else {
                  this.value = "";
                }
              });
            });
          }

          export const getFilteredCrossSellingProducts = (crossSellingProducts:any[],product:Product):any[]=>{
            let filteredCrossSellingProducts = [];
               crossSellingProducts.forEach(_prod => {
                if (_prod.id === product.id) {
                    return;
                }
                filteredCrossSellingProducts.push(_prod)
                })
        return filteredCrossSellingProducts;
        }