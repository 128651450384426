import 'yup-phone'

import {ErrorMessage, Field, Form, Formik, useFormik, useFormikContext} from 'formik'
import React, {useEffect, useState, useContext} from 'react'
import {useDispatch, useSelector} from "react-redux";
import HTMLReactParser from 'react-html-parser';
import {PersistFormikValues} from 'formik-persist-values'
import {Redirect} from 'react-router-dom'
import {useHistory} from 'react-router-dom';
import {getValidationSchema,handleSalutations,handleCountries,phoneRegExp,nameRegExp,titles} from "./PersonalDataForm.service";
import ShopwareAPI, {BASE_ENDPOINT} from "../../ShopwareAPI/ShopwareAPI";
import AddressRepository from "../../Checkout/AddressRepository";
import Button from "chamaeleon-react/components/Button/Button";
import {SubmitButton} from "@acl/design-form";
// import SubmitButton  from "chamaeleon-react/components/Formik-CustomInputs/SubmitButton";
import SalutationField from './SalutationField.tsx';
import CountriesField from './CountriesField';
import LoginInfo from './LoginInfo';
import { StoreContext } from "../../Store";
import { v4 as uuidv4 } from 'uuid';
import { formatTimeDelta } from 'react-countdown';
import { nodeModuleNameResolver } from 'typescript';



interface AppProps {
    customer:any,
    address:any,
    mode:"register"|"edit",
    closeModalFunc?:()=>void,
    addressParam?:string,
    
}

function PersonalDataForm({customer,address,mode,closeModalFunc,addressParam}:AppProps) {
    const {contextState, setValues} = useContext(StoreContext);
    const history = useHistory();
    const translations = contextState.translations;
    const countries = contextState.countries;
    const salutations = contextState.salutations;
    const checkout = contextState.checkout;
    const newsletter = contextState.newsletter;
    const [toNext, setToNext] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const {selectedLanguageName} = contextState;

    useEffect(() => {
        setValues({type: "set", field: "isFormSubmitted", value: false})
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            if (addressParam === "shippingAddress") {

                setInitialValues({
                    salutation: checkout.shippingAddress.salutation,
                    // salutationId: checkout.shippingAddress.salutationId ,
                    salutationId: "74454d3c67aa4847b5443a730f352a84",
                    title: checkout.shippingAddress.title,
                    firstname: checkout.shippingAddress.firstname,
                    lastname: checkout.shippingAddress.lastname,
                    birthdayDay: checkout.shippingAddress.birthdayDay,
                    birthdayMonth: checkout.shippingAddress.birthdayMonth,
                    birthdayYear: checkout.shippingAddress.birthdayYear ,
                    newsletter: newsletter ,
                    street: checkout.shippingAddress.street,
                    housenumber: checkout.shippingAddress.housenumber ,
                    staircase: checkout.shippingAddress.staircase,
                    doornumber: checkout.shippingAddress.doornumber,
                    areacode: checkout.shippingAddress.areacode,
                    country: checkout.shippingAddress.country ,
                    countryId: checkout.shippingAddress.countryId,
                    city: checkout.shippingAddress.city,
                    email: checkout.shippingAddress.email,
                    phoneNumber: checkout.shippingAddress.phoneNumber
                })
            } else {

                setInitialValues({
                    salutation: checkout.address.salutation,
                    //salutationId: checkout.address.salutationId ,
                    salutationId: "74454d3c67aa4847b5443a730f352a84",
                    title: checkout.address.title,
                    firstname: checkout.address.firstname,
                    lastname: checkout.address.lastname,
                    birthdayDay: checkout.address.birthdayDay,
                    birthdayMonth: checkout.address.birthdayMonth,
                    birthdayYear: checkout.address.birthdayYear ,
                    newsletter: newsletter ,
                    street: checkout.address.street,
                    housenumber: checkout.address.housenumber ,
                    staircase: checkout.address.staircase,
                    doornumber: checkout.address.doornumber,
                    areacode: checkout.address.areacode,
                    country: checkout.address.country ,
                    countryId: checkout.address.countryId,
                    city: checkout.address.city,
                    email: checkout.address.email,
                    phoneNumber: checkout.address.phoneNumber
                })
            }
        }
        else if ( contextState?.context?.customer) {

            setInitialValues({
                salutation: contextState.context.customer.salutation.displayName,
                // salutationId: contextState.context.customer.salutation.id , 
                salutationId: "74454d3c67aa4847b5443a730f352a84",
                title: contextState.context.customer.title,
                firstname: contextState.context.customer.firstName,
                lastname: contextState.context.customer.lastName,
                birthdayDay: contextState?.context.customer?.customFields?.birthdayDay,
                birthdayMonth: contextState?.context.customer?.customFields?.birthdayMonth,
                birthdayYear: contextState?.context.customer?.customFields?.birthdayYear ,
                street: contextState.context.customer.defaultShippingAddress?.street,
                housenumber: contextState.context.customer.defaultShippingAddress?.customFields?.custom_houseNumber ,
                staircase: checkout.address.staircase,
                doornumber: contextState.context.customer.defaultShippingAddress?.customFields?.doorNumber,
                areacode: contextState.context.customer.defaultShippingAddress?.zipcode,
                country: contextState.context.customer.defaultShippingAddress?.country.name ,
                countryId:contextState.cart.deliveries[0]?.location.address.country.id,
                city: contextState.context.customer.defaultShippingAddress?.city,
                email: contextState.context.customer.email,
            })
        } else if (Object.keys(checkout.address).length>0 && checkout.address.firstname) {
            setInitialValues({
                        salutation: checkout.address.salutation,
                        // salutationId: checkout.address.salutationId ,
                        salutationId: "74454d3c67aa4847b5443a730f352a84",
                        title: checkout.address.title,
                        firstname: checkout.address.firstname,
                        lastname: checkout.address.lastname,
                        birthdayDay: checkout.address.birthdayDay,
                        birthdayMonth: checkout.address.birthdayMonth,
                        birthdayYear: checkout.address.birthdayYear ,
                        newsletter: newsletter ,
                        street: checkout.address.street,
                        housenumber: checkout.address.housenumber ,
                        staircase: checkout.address.staircase,
                        doornumber: checkout.address.doornumber,
                        areacode: checkout.address.areacode,
                        country: checkout.address.country ,
                        countryId: checkout.address.countryId,
                        city: checkout.address.city,
                        email: checkout.address.email,
                        phoneNumber: checkout.address.phoneNumber
                    })
        }
    }, [contextState.checkout.address])
    
    
    
    const validationSchema = getValidationSchema(translations,nameRegExp,phoneRegExp);
        
    function onSubmit(values,  {resetForm}) { 
        const updatedValues = {
            ...values,
            salutationId: "74454d3c67aa4847b5443a730f352a84",  // Ensure default value
        };

        if(mode === "register"){
            setValues({type: "setAddress", value: updatedValues});
            setValues({type: "setShippingAddress", value: updatedValues});
            setValues({type: "set", field: "isFormSubmitted", value: true})
            resetForm();
            if(history.location.pathname.includes("/en") || history.location.pathname.includes("/en/")) {
                history.push('/en/checkout/summary')
                return 
            }
            history.push('/checkout/summary')
        }

        else if (mode === "edit") {
            setValues({type: addressParam == "address" ? "setAddress" : "setShippingAddress", value: values});
            closeModalFunc();
        }
        
    }



    const updateShippingCosts = (cart)=>{
        let gaData = JSON.parse(sessionStorage.getItem("ga-data"));
        let shippingCosts;
        if(cart.deliveries.length>1){
            shippingCosts = cart.deliveries.reduce((a,b)=>(a.shippingCosts.totalPrice+b.shippingCosts.totalPrice))
        }
        else {
            shippingCosts= cart.deliveries[0].shippingCosts.totalPrice;
        }
        gaData.shipping = shippingCosts;
        sessionStorage.setItem("ga-data",JSON.stringify(gaData));
    }

    function renderFormHeadline() {
        if (customer?.id) {
            return <React.Fragment></React.Fragment>;
        }
        return <h2>{translations['custom.shippingDetail.guest']}</h2>;
    }


    /* function nextDisabled() {
      return this.state.isStepComplete ? "" : "disabled";
    } */


    return (
                            <Formik
                                enableReinitialize={true}
                                initialValues={
                                    initialValues 
                                }
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {formik => {
                                    return (
                                        <Form
                                            name="checkout_address"
                                            id="checkout_address"
                                            className="cham-form"
                                        >
                                            {/* {(window.onload = () => formik.resetForm())} */}
                                            {/* <PersistFormikValues name="VersandInformationen"/> */}

                                            {mode === "register" && ! contextState?.context?.customer?.customFields?.custom_postcustomer_adb2c &&
                                            <>
                                            <h3>{translations['custom.shippingDetail.header']} </h3>
                                            <LoginInfo
                                                props={address}
                                                translations={translations}
                                                ClickHandler={() => {
                                                                        let api = new ShopwareAPI();
                                                                        api.fetch('/kiam-login', 'GET')
                                                                        .then((response: any) => {
                                                                                window.localStorage.setItem('redirectTo', document.location.pathname);
                                                                                document.location.href = response.link;
                                                                                });
                                                                        }}
                                                />
                                            
                                            {renderFormHeadline()}
                                            </>
                                            }

                                            <h4>{translations['custom.shippingDetail.text']} </h4>
                                            <div className="container-fluid versandinformationen">
                                                <div className="row"> 
                                                    {/*
                                                    <div className="col-12 col-md-6">
                                                        <SalutationField
                                                            formik={formik}
                                                            translations={translations}
                                                            salutations={salutations}
                                                            state={initialValues}
                                                            changeHandler={e => {
                                                                formik.handleChange(e)
                                                                formik.setFieldValue(
                                                                'salutation',
                                                                handleSalutations(e.currentTarget.value,salutations)
                                                                )}
                                                            }
                                                        />                                                        
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="title">{translations['account.personalTitleLabel']}</label>
                                                        <div className="select__input select_input--secondary">
                                                            <Field
                                                                as="select"
                                                                name="title"
                                                                className="cham-form"
                                                                placeholder="Titel"
                                                            >
                                                        
                                                                <option
                                                                    value="">{translations['custom.select.text']}</option>
                                                                    {titles && titles.map((title)=><option value={title} key={uuidv4()}> {title}</option>)}
                                                            </Field>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="firstname">{translations['account.personalFirstNameLabel']}</label>
                                                        <Field
                                                            name="firstname"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="Maxi"
                                                        />
                                                        <ErrorMessage
                                                            name="firstname"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="lastname">{translations['account.personalLastNameLabel']}</label>
                                                        <Field
                                                            name="lastname"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="Muster"
                                                        />
                                                        <ErrorMessage
                                                            name="lastname"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {mode === "register" && <>
                                            <h4>{translations['custom.birthday.label']}</h4>
                                            <div className="container-fluid versandinformationen">
                                                <div className="row">
                                                    <div className="col-4 col-md-4">
                                                        <label
                                                            htmlFor="birthdayDay">{translations['account.personalBirthdaySelectDay']}</label>
                                                        <Field
                                                            name="birthdayDay"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="29"
                                                        />
                                                        <ErrorMessage
                                                            name="birthdayDay"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-4 col-md-4">
                                                        <label
                                                            htmlFor="birthdayMonth">{translations['account.personalBirthdaySelectMonth']}</label>
                                                        <Field
                                                            name="birthdayMonth"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="05"
                                                        />
                                                        <ErrorMessage
                                                            name="birthdayMonth"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-4 col-md-4">
                                                        <label
                                                            htmlFor="birthdayYear">{translations['account.personalBirthdaySelectYear']}</label>
                                                        <Field
                                                            name="birthdayYear"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="1990"
                                                        />
                                                        <ErrorMessage
                                                            name="birthdayYear"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <h4>{translations['custom.benutzerdaten.label']}</h4>
                                            <div className="container-fluid versandinformationen">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="phoneNumber">{translations['address.phoneNumberLabel']}</label>
                                                        <Field
                                                            name="phoneNumber"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="06641111111"
                                                        />
                                                        <ErrorMessage
                                                            name="phoneNumber"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="email">{translations['account.loginMailPlaceholder']}</label>
                                                        <Field
                                                            name="email"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="maxi@muster.at"
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                            }


                                            <h4>{translations['custom.address.label']}</h4>
                                            <div className="container-fluid versandinformationen">
                                                <div className="row address_style">
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="street">{translations['address.streetLabel']}</label>
                                                        <Field
                                                            name="street"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. Mariahilferstraße"
                                                        />
                                                        <ErrorMessage
                                                            name="street"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="housenumber">{translations['address.housenumberLabel']}</label>
                                                        <Field
                                                            name="housenumber"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. 87"
                                                        />
                                                        <ErrorMessage
                                                            name="housenumber"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="staircase">{translations['address.staircaseLabel']}</label>
                                                        <Field
                                                            name="staircase"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. 4"
                                                        />
                                                        <ErrorMessage
                                                            name="staircase"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label
                                                            htmlFor="doornumber">{translations['address.doorLabel']}</label>
                                                        <Field
                                                            name="doornumber"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. 42"
                                                        />
                                                        <ErrorMessage
                                                            name="doornumber"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className={mode==="register" ? "col-12 col-md-3" : "col-12 col-md-6" }>
                                                        <label
                                                            htmlFor="areacode">{translations['address.zipcodeLabel']}</label>
                                                        <Field
                                                            name="areacode"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. 1060"
                                                        />
                                                        <ErrorMessage
                                                            name="areacode"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className={mode==="register" ? "col-12 col-md-3" : "col-12 col-md-6" }>
                                                        <label
                                                            htmlFor="city">{translations['address.cityLabel']}</label>
                                                        <Field
                                                            name="city"
                                                            className="cham-form"
                                                            type="text"
                                                            placeholder="z.B. Wien"
                                                        />
                                                        <ErrorMessage name="city" component="div" className="error"/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        {/* <div className="select__input select_input--secondary"> */}

                                            
                                                    <CountriesField
                                                            translations={translations}
                                                            countries={countries}
                                                            state={initialValues}
                                                            changeHandler={e => {
                                                                formik.handleChange(e);
                                                                formik.setFieldValue(
                                                                    'country',
                                                                    handleCountries(e.currentTarget.value,countries)
                                                                );
                                                                let countryId = e.currentTarget.value;
                                                                AddressRepository.updateCountry(countryId, contextState.context.customer)
                                                                    .then((cart) => {
                                                                        updateShippingCosts(cart)
                                                                        setValues({
                                                                            type: 'set',
                                                                            field: 'cart',
                                                                            value: cart
                                                                        });
                                                                    });
                                                            }}
                                                        />
                                                            {/* </div> */}
                                                                </div>
                                                                </div>
                                                                


                                                                {mode==="register" &&
                                                                <>
                                                                <div className="col-12 col-md-12">
                                                                <label className="sc-form__checkbox" htmlFor="newsletter">
                                                                <Field type="checkbox" id="newsletter" name="newsletter" checked={formik.values.newsletter} onChange={e => formik.setFieldValue('newsletter', e.target.checked)} />
                                                                <span className="sc-form__focus-indicator"></span>
                                                                <div className="sc-form__checkbox__label">
                                                            {translations['custom.label.newsletter']}​
                                                                </div>
                                                                <span className="sc-form__checkbox__indicator"></span>
                                                                </label>
                                                                </div>
                                                                


                                                                <div className="col-12 col-md-12">
                                                                <div className="sc-form__checkbox__label agb_label">
                                                            {HTMLReactParser(translations['custom.text.newsletter'])}
                                                                </div>
                                                                </div>
                                                                </>
                                                                }
                                                                </div>
                                                                

                                                                {mode === "register" && <>
                                                                <p>

                                                                 <SubmitButton
                                                                    text={translations['account.registerSubmit']}
                                                                    classes={'linkbtn linkbtn__primary '}
                                                                 />

{/* 
                                                                <button type="submit" className='linkbtn linkbtn__primary '>
                                                                <span
                                                                className="linkbtn__text">{translations['account.registerSubmit']}</span>
                                                                <span
                                                                className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                                                                </button> */}
                                                                </p>
                                                                </>
                                                                }

                                                                {mode==="edit"&&
                                                                <div className="container-fluid">
                                                                            <div className="row confirm-buttons">
                                                                                <div className="col-12 col-md-6">
                                                                                <Button
                                                                                    classes="linkbtn linkbtn__secondary"
                                                                                    text= {translations['detail.reviewLoginCancelButton']}
                                                                                    variant="secondary"
                                                                                    icon="posticon_Posticon-Button-x-Abbrechen"
                                                                                    onClickFn={()=>closeModalFunc()}
                                                                                />
                                                                                </div>
                                                                                <div className="col-12 col-md-6">

                                                                                <SubmitButton
                                                                                    text= {translations['custom.adressmodal.text']}
                                                                                    classes={'linkbtn linkbtn__primary '}
                                                                                />
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                            }


                                                            {toNext ? <Redirect to="/checkout/summary"/> : null}
                                                                </Form>
                                                                )
                                                            }}
                                    </Formik>
                                    



                                    )
                                }

export default PersonalDataForm
